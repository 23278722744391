import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
//
import { useResponsive } from 'src/hooks/use-responsive';
import { HeaderSimple as Header } from '../_common';

// ----------------------------------------------------------------------

export default function CompactLayout({ children }) {
  const lgUp = useResponsive('up','lg');
  return (
    <>
      {lgUp && <Header />}
      <Stack sx={{ background: '#FDF3F8' }}>
        <Container component="main">
          <Stack
            sx={{
              py: 12,
              m: 'auto',
              minHeight: '100vh',
            }}
          >
            {children}
          </Stack>
        </Container>
      </Stack>
    </>
  );
}

CompactLayout.propTypes = {
  children: PropTypes.node,
};
