import { alpha, Box, Button, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import Iconify from '../iconify';

export default function AddPackageCard() {
  const router = useRouter();

  return (
    <Box sx={{ background: 'white', padding: 3, borderRadius: 2 }}>
      <Box
        sx={{
          height: 140,
          borderRadius: 2,
          marginBottom: 6,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
          border: (theme) => `dashed 1px ${theme.palette.divider}`,
        }}
      >
        {' '}
        <IconButton size="large">
          <Iconify icon="tabler:plus" width={40} color="#D92D7F" />
        </IconButton>
      </Box>
      <Button
        variant="contained"
        onClick={() => {
          router.push(paths.clinic_dashboard.add_package);
        }}
        sx={{
          width: 1,
          boxShadow: 10,
          fontSize: { lg: '16px', xs: '12px' },
          background: '#D92D7F',
          ':hover': { background: '#D92D7F' },
        }}
      >
        Add New Package
      </Button>
    </Box>
  );
}
