import { Navigate, useRoutes } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN,DEFAULT_PATH } from 'src/config-global';
//
import { mainRoutes } from './main';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';

import { clinicDashboardRoutes } from './clinic_dashboard';

import { doctorDashboardRoutes } from './doctor_dashboard';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={DEFAULT_PATH} replace />,
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Clinic Dashboard routes
    ...clinicDashboardRoutes,

    // Doctor Dashboard routes
    ...doctorDashboardRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
