import { Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import ClinicDoctorsView from 'src/sections/clinic-dashboard/doctors/clinic-doctors-view';

const ClinicDoctorPage = () => {
  console.log('hELLO');
  return (
    <>
      <Helmet>
        <title>Clinic Doctors</title>
      </Helmet>

      <ClinicDoctorsView />
    </>
  );
};

export default ClinicDoctorPage;
