import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  auth: {
    login: '/v1/auth/login',
  },
  clinic: {
    get: '/v1/clinic',
    create: '/v1/clinic',
  },
};

export const requestWithToken = async (method, url, data = {}, token = '') => {
  try {
    const response = await axios({
      method,
      url,
      data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log('error = ', error);
    // If the server responds with an error (e.g., 4xx or 5xx status codes), throw it so that it can be caught in the .catch block
    if (error.response) {
      throw new Error(error.response.data.message || 'Request failed');
    }
    // Handle other types of errors (e.g., network errors)
    throw new Error(error.message || 'An unknown error occurred');
  }
};

export const requestWithoutToken = async (method, url, data = {}) => {
  try {
    console.log(data);
    const response = await axios({
      method,
      url,
      data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.log('error = ', error);
    // If the server responds with an error (e.g., 4xx or 5xx status codes), throw it so that it can be caught in the .catch block
    if (error.response) {
      throw new Error(error.response.data.message || 'Request failed');
    }
    // Handle other types of errors (e.g., network errors)
    throw new Error(error.message || 'An unknown error occurred');
  }
};

export const getData = async (url, token = getToken(), data = null) =>
  requestWithToken('get', url, data, token);

export const postData = async (url, data = {}, token = getToken()) =>
  requestWithToken('post', url, data, token);


export const postDataGuest = async (url, data = {}) => requestWithoutToken('post', url, data);

export const getDataGuest = async (url) => requestWithoutToken('get', url);

export const putDataGuest = async (url) => requestWithoutToken('put', url);

export const putData = async (url, data = {}, token = getToken()) =>
  requestWithToken('put', url, data, token);

export const deleteData = async (url, token = getToken()) => requestWithToken('delete', url, {}, token);

export const postFormData = async (url, formData, token = getToken()) => {
  try {
    const response = await axios({
      method: 'post',
      url,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data', // Use multipart/form-data for form data
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log('error = ', error);
    // If the server responds with an error (e.g., 4xx or 5xx status codes), throw it so that it can be caught in the .catch block
    if (error.response) {
      throw new Error(error.response.data.message || 'Request failed');
    }
    // Handle other types of errors (e.g., network errors)
    throw new Error(error.message || 'An unknown error occurred');
  }
};

export const getToken = () => {
  const STORAGE_KEY = 'accessToken';
  return localStorage.getItem(STORAGE_KEY);
};
