import { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Alert,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';

import Iconify from 'src/components/iconify';
import { createClinic } from 'src/services/clinic.service';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useBoolean } from 'src/hooks/use-boolean';
import { updateStaffPassword } from 'src/services/staff.service';

export default function UpdateUserPassword({ email }) {
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State to control Snackbar visibility
  const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity
  const password = useBoolean();
  const confirmPassword = useBoolean();

  const UpdatePasswordSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .min(8, 'Confirm Password must be at least 8 characters'),
  });

  const defaultValues = {
    email,
    password: '',
    confirmPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdatePasswordSchema),
    defaultValues,
  });

  const { register, handleSubmit, formState } = methods;
  const { errors } = formState;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const onSubmit = (data) => {
    console.log(data);
    if (data.password !== data.confirmPassword) {
      setErrorMsg('Passwords do not match');
      return;
    }

    updateStaffPassword({ email: data.email, password: data.password })
      .then(() => {
        setSnackbarOpen(true);
        setSnackbarMessage('Password updated successfully');
        setSnackbarSeverity('success');
        handleClose();
      })
      .catch((error) => {
        console.error(error);
        methods.reset();
        setErrorMsg(typeof error === 'string' ? error : error.message);
      });
  };

  return (
    <>
      <IconButton size="small" onClick={handleClickOpen}>
        <Iconify icon="tabler:edit" width={24} />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Password</DialogTitle>
        <DialogContent>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2.5} sx={{ marginTop: '10px', width: '350px' }}>
              {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
              <RHFTextField name="email" label="Email Address" disabled fullWidth />
              <RHFTextField
                name="password"
                label="Password"
                type={password.value ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={password.onToggle} edge="end">
                        <Iconify
                          icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <RHFTextField
                name="confirmPassword"
                label="Confirm Password"
                type={password.value ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={confirmPassword.onToggle} edge="end">
                        <Iconify
                          icon={confirmPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Update Password</Button>
              </DialogActions>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
