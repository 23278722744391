import PropTypes from 'prop-types';
import { Avatar, Button, Chip, Icon, ListItemText, TableCell, TableRow } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { usePathname, useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { removeProcedure } from 'src/services/procedures.service';

const STATUS_OPTIONS = {
  open: { label: 'Open', color: 'default' },
  assigned_to_clinic: { label: 'Assigned To Clinic', color: 'success' },
  cancelled: { label: 'Cancelled', color: 'error' },
  ready_to_buy: { label: 'Ready To Buy', color: 'warning' },
  free_consultation: { label: 'Free Consultation', color: 'primary' },
  package_sold: { label: 'Package Sold', color: 'success' },
};

export default function EnquiryTableRow({ row }) {
  const { enquiry_id, customer_name, area_of_concern, phone_number, status, createdOn } = row;
  const router = useRouter();

  const path = usePathname();

  const splitPath = path.slice(1).split('/');
  const isDashboard = splitPath[0] === 'dashboard';

  const handleViewRow = () => {
    if (isDashboard) {
      router.replace(paths.dashboard.enquiry.view.replace(':enquiryId', enquiry_id));
    } else {
      router.replace(paths.clinic_dashboard.enquriy_details.replace(':enquiryId', enquiry_id));
    }
  };

  return (
    <>
      <TableRow onClick={handleViewRow} hover sx={{ cursor: 'pointer' }}>
        <TableCell
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            maxWidth: 250,
          }}
        >
          <Avatar alt={customer_name} sx={{ mr: 2 }}>
            {customer_name.charAt(0).toUpperCase()}
          </Avatar>

          <ListItemText
            sx={{
              maxWidth: 250,
            }}
            primary={customer_name}
            primaryTypographyProps={{
              typography: 'body2',
              maxWidth: 250,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          />
        </TableCell>

        <TableCell>
          <Label variant="ghost" color="info">
            {phone_number}
          </Label>
        </TableCell>

        <TableCell>
          <Label variant="ghost" color="info">
            {new Date(createdOn).toDateString()}
          </Label>
        </TableCell>

        <TableCell>
          <Label variant="ghost" color="info">
            {area_of_concern}
          </Label>
        </TableCell>

        <TableCell>
          <Chip
            label={STATUS_OPTIONS[status]?.label}
            color={STATUS_OPTIONS[status]?.color}
          />
        </TableCell>

        <TableCell>
          <IconButton size="small" onClick={() => handleViewRow()}>
            <Iconify icon="tabler:eye" wenquiry_idth={24} />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}

EnquiryTableRow.propTypes = {
  row: PropTypes.object.isRequired,
};
