import React from 'react';
import { Helmet } from 'react-helmet-async';
import DoctorProfileEditView from 'src/sections/doctor-dashboard/profile/doctor-profile-edit-view';

const DoctorProfileEditPage = () => (
  <>
    <Helmet>
      <title>Edit Doctor Profile</title>
    </Helmet>

    <DoctorProfileEditView />
  </>
);

export default DoctorProfileEditPage;
