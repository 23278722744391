import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Alert,
  Snackbar,
  Typography,
  Stack,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';
import RHFImageUpload from 'src/components/hook-form/rhf-image-upload';
import Iconify from 'src/components/iconify';
import { addProcedureContent, editProcedure } from 'src/services/procedures.service';
import {
  getBodyParts,
  getConcerns,
  getProcedures,
  getTreatmentId,
} from 'src/services/treatments.service';
import { uploadImageFormData } from 'src/services/image.service';
import { useRouter } from 'src/routes/hooks';

export default function EditProcedurePopup({
  fieldName,
  fieldContent,
  fieldSubHeading,
  procedureId,
}) {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [selectedBodyPart, setSelectedBodyPart] = useState('');
  const [selectedProcedure, setSelectedProcedure] = useState('');
  const [selectedConcern, setSelectedConcern] = useState('');
  const [loading, showLoading] = useState(false);
  const [treatmentIds, setSelectedTreatmentIds] = useState([]);
  const [bodyParts, setBodyParts] = useState([]);
  const [procedures, setProcedures] = useState([]); // Store procedures
  const [concerns, setConcerns] = useState([]); // Store concerns

  const router = useRouter();

  const form = useForm({
    defaultValues: {
      [fieldName]: fieldContent || '',
    },
  });

  const { register, handleSubmit, setValue, formState } = form;
  const { errors } = formState;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (fieldName === 'treatment_id') {
      getBodyParts().then((avalbileBodyParts) => {
        setBodyParts(avalbileBodyParts);
      });
    }
  }, [fieldName, fieldContent]);

  const handleBodyPartChange = async (event) => {
    const bodyPartId = event.target.value;
    console.log(bodyPartId);
    setSelectedBodyPart(bodyPartId);
    showLoading(true);

    // Fetch procedures based on the selected body part
    const proceduresList = await getProcedures(bodyPartId);
    setProcedures(proceduresList);
    showLoading(false);
  };

  const handleProcedureChange = async (event) => {
    const procedure = event.target.value;
    setSelectedProcedure(procedure);

    // Fetch concerns based on the selected procedure
    const concernsList = await getConcerns(selectedBodyPart, procedure);
    setConcerns(concernsList);
  };

  const handleConcernChange = async (event) => {
    const concern = event.target.value;
    setSelectedConcern(concern);

    const treatment_id = await getTreatmentId(selectedBodyPart, selectedProcedure, concern);
    setSelectedTreatmentIds([treatment_id]);
  };

  const handleImageUpload = async (blobInfo, success, failure) => {
    // Here you would normally upload the image to your server
    // For example, using fetch to send the blobInfo to an API endpoint
    const formData = new FormData();
    formData.append('image', blobInfo.blob(), blobInfo.filename());

    const imageName = `procedure_image_${new Date().toISOString()}`;
    const folderName = `images/procedures/${procedureId}`;

    const url = await uploadImageFormData(formData, imageName, folderName);
    return url.url;
  };

  const onSubmit = (data) => {
    showLoading(true);
    if (fieldName === 'content') {
      const finalData = {
        heading: fieldSubHeading,
        description: data.content,
      };
      addProcedureContent(procedureId, { content: finalData })
        .then(() => {
          setSnackbarMessage('Procedure content updated successfully');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          handleClose();
          router.reload();
        })
        .catch(() => {
          setSnackbarMessage('Failed to update procedure content');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
    } else if (fieldName === 'image') {
      // Handle image-specific form submission if necessary
      console.log(data.image);
      editProcedure(procedureId, { image: data.image || '' })
        .then(() => {
          setSnackbarMessage('Procedure image updated successfully');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          handleClose();
          router.reload();
        })
        .catch(() => {
          setSnackbarMessage('Failed to update procedure image');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });

      console.log(data); // For debugging purposes
      // You might need to handle image uploads differently
    } else if (fieldName === 'treatment_id') {
      console.log(data);
      getTreatmentId(selectedBodyPart, selectedProcedure, selectedConcern).then((treatmentId) => {
        editProcedure(procedureId, { treatment_id: [treatmentId] }).then(() => {
          setSnackbarMessage('Procedure treatment ID updated successfully');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          handleClose();
          router.reload();
        });
      });
    } else {
      editProcedure(procedureId, data)
        .then(() => {
          setSnackbarMessage('Procedure updated successfully');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          handleClose();
          router.reload();
        })
        .catch((err) => {
          setSnackbarMessage('Failed to update procedure');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
    }
    showLoading(false);
  };

  return (
    <div>
      <Button variant="text" onClick={handleClickOpen} startIcon={<Iconify icon="tabler:edit" />}>
        Edit {fieldName}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={fieldName === 'content' ? 'lg' : 'sm'}
        sx={{
          '& .MuiDialog-paper': {
            minHeight: fieldName === 'content' ? '80vh' : 'auto',
          },
        }}
        fullWidth
      >
        <DialogTitle>Edit {fieldName}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            {fieldName === 'name' && (
              <TextField
                autoFocus
                margin="dense"
                id="procedureName"
                name="name"
                label="Procedure Name"
                {...register('name', { required: 'Procedure Name is required' })}
                fullWidth
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}

            {fieldName === 'description' && (
              <TextField
                margin="dense"
                id="description"
                name="description"
                label="Description"
                multiline
                {...register('description', { required: 'Description is required' })}
                fullWidth
                variant="outlined"
                rows={4}
                error={!!errors.description}
                helperText={errors.description?.message}
              />
            )}

            {fieldName === 'treatment_id' && (
              <Stack direction={{ xs: 'column', lg: 'row' }} spacing={2}>
                <Grid item xs={12} lg={4}>
                  <FormControl fullWidth margin="dense">
                    <InputLabel id="body-part-select">Select Body Part</InputLabel>
                    <Select
                      id="body-part-select"
                      value={selectedBodyPart}
                      onChange={handleBodyPartChange}
                      label="Select Body Part"
                      style={{ minWidth: 150 }} // Ensuring a minimum width for the Select
                    >
                      {bodyParts.map((part) => (
                        <MenuItem key={part} value={part}>
                          {part}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl fullWidth margin="dense">
                    <InputLabel id="procedure-select">Select Procedure</InputLabel>
                    <Select
                      id="procedure-select"
                      value={selectedProcedure}
                      disabled={loading}
                      onChange={handleProcedureChange}
                      label="Select Procedure"
                      style={{ minWidth: 150 }} // Ensuring a minimum width for the Select
                    >
                      {procedures.map((procedure) => (
                        <MenuItem key={procedure} value={procedure}>
                          {procedure}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControl fullWidth margin="dense">
                    <InputLabel id="concern-select">Select Concern</InputLabel>
                    <Select
                      id="concern-select"
                      value={selectedConcern}
                      onChange={handleConcernChange}
                      label="Select Concern"
                      style={{ minWidth: 150 }} // Ensuring a minimum width for the Select
                    >
                      {concerns.map((concern) => (
                        <MenuItem key={concern} value={concern}>
                          {concern}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Stack>
            )}

            {fieldName === 'content' && (
              <>
                <Typography variant="subtitle1">{fieldSubHeading}</Typography>
                <Editor
                  tinymceScriptSrc="/tinymce/js/tinymce/tinymce.min.js"
                  initialValue={fieldContent || ''}
                  init={{
                    height: 600, // Set the editor height
                    menubar: false,
                    plugins: 'image powerpaste',
                    images_upload_handler: handleImageUpload,
                    toolbar:
                      'undo redo | blocks | bold italic backcolor image | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                    content_style: `
                      body { 
                        margin: 10px; 
                        padding: 10px;
                        line-height: 1.6; 
                        font-family: Arial, sans-serif; 
                      }
                      h1, h2, h3, h4, h5, h6 {
                        margin-top: 0.5em; 
                        margin-bottom: 0.5em;
                      }
                      p {
                        margin: 0 0 1em 0;
                      }
                      ul, ol {
                        margin: 0 0 1em 20px;
                      }
                      code {
                        background: #f4f4f4; 
                        padding: 2px 4px; 
                        border-radius: 4px;
                      }
                    `,
                  }}
                  onEditorChange={(content) => {
                    console.log(content);
                    setValue('content', content);
                  }}
                />
                {errors.content && (
                  <Typography color="error" variant="caption">
                    {errors.content?.message}
                  </Typography>
                )}
              </>
            )}

            {fieldName === 'image' && (
              <RHFImageUpload
                initialImage={fieldContent.length === 0 ? null : fieldContent}
                fileName={`procedure_image_${new Date().toISOString()}`}
                folderName={procedureId}
                displayImages
                maxFiles={1}
                filesRequired={1}
                name="image"
                setValue={setValue}
                width={430}
                height={310}
                label="Upload Image"
                maxHeight={240}
                uploadBox
              />
            )}

            {fieldName === 'video' && (
              <TextField
                autoFocus
                margin="dense"
                id="procedureVideo"
                name="name"
                label="Procedure Video"
                {...register('video')}
                fullWidth
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.video}
              />
            )}

            <DialogActions>
              <Button onClick={handleClose} disabled={loading}>
                Cancel
              </Button>
              <Button type="submit" disabled={loading}>
                Save
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
