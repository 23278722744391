import { postData, getData, putData, getDataGuest } from 'src/utils/axios';
import { urlMap } from 'src/utils/urls';

export const getStaffList = async (pageValue = 1, limitValue = 10) => {
  const url = `${urlMap.staff.list
    .replace('pageValue', `${pageValue}`)
    .replace('limitValue', `${limitValue}`)}`;
  return getData(url);
};

export const addStaff = async (staffData) => {
  const url = `${urlMap.staff.create}`;
  return postData(url, staffData);
};

export const updateStaffPassword = async (staffData) => {
  const url = `${urlMap.staff.updateUserPassword}`;
  return putData(url, staffData);
};
