import { postData, getData, putData, getDataGuest, deleteData } from 'src/utils/axios';
import { urlMap } from 'src/utils/urls';

export const getPackages = async (clinicId) => {
  console.log(clinicId);
  const url = `${urlMap.packages.list.replace(':clinicId', clinicId)}`;
  return getData(url);
};

export const addPackage = async (packageData) => {
  const url = `${urlMap.packages.create}`;
  return postData(url, packageData);
};


export const deletePackage = async (packageId) => {
  const url = `${urlMap.packages.delete.replace(":packageId", packageId)}`;
  return deleteData(url);
};


export const getPackageDetails = async (packageId) => {
  const url = `${urlMap.packages.getDetails.replace(":packageId", packageId)}`;
  return getData(url);
};

export const updatePackage = async (packageId, packageData) => {
  const url = `${urlMap.packages.update.replace(":packageId", packageId)}`;
  return putData(url, packageData);
};


export const publishPackage = async (packageId, packageData) => {
  const url = `${urlMap.packages.publish.replace(":packageId", packageId)}`;
  return putData(url, packageData);
};