import { postData, getData, putData, getDataGuest, postFormData } from 'src/utils/axios';
import { urlMap } from 'src/utils/urls';

export const resizeImage = async (file, maxWidth, maxHeight) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target.result;

      img.onload = () => {
        let width = img.width;
        let height = img.height;

        const widthScaleFactor = maxWidth / width;
        const heightScaleFactor = maxHeight / height;
        const scaleFactor = Math.min(widthScaleFactor, heightScaleFactor);

        if (scaleFactor < 1) {
          width *= scaleFactor;
          height *= scaleFactor;

          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob) {
                const resizedFile = new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                });
                resolve(resizedFile);
              } else {
                reject(new Error('Canvas toBlob conversion failed'));
              }
            },
            file.type,
            1 // Quality, from 0 to 1
          );
        } else {
          resolve(file); // Return original file if no resizing is needed
        }
      };
    };

    reader.onerror = () => reject(new Error('FileReader error'));
    reader.readAsDataURL(file);
  });

export const uploadImageFormData = async (formData, filename, folderName) => {
  const url = `${urlMap.images.upload
    .replace('FileName', filename)
    .replace('FolderName', folderName)}`;
  return postFormData(url, formData);
};
