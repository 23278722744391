import { alpha, Box, Button, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { publishPackage } from 'src/services/package.service';
import Iconify from '../iconify';
import DeleteDialog from './delete-package-modal';

export default function PackageCard({ cardDetails }) {
  const router = useRouter();
  const { id, package_name, package_promo_image, published } = cardDetails;

  // Debugging: Check the value of `published`
  console.log('Published status:', published);

  const onClickPublish = () => {
    publishPackage(id, { published: true }).then((data) => {
      console.log(data);
      router.reload();
    });
  };

  const onClickUnpublish = () => {
    publishPackage(id, { published: false }).then((data) => {
      console.log(data);
      router.reload();
    });
  };

  return (
    <Box sx={{ background: 'white', padding: 3, borderRadius: 2 }}>
      {package_promo_image[0] !== '' ? (
        <img
          src={package_promo_image[0]}
          alt="Package Promo"
          style={{ borderRadius: '14px', width: '100%' }}
        />
      ) : (
        <Box
          sx={{
            height: 140,
            borderRadius: 2,
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.04),
            border: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        />
      )}
      <Stack direction="row" justifyContent="space-between" alignItems="center" marginY="6px">
        <Typography fontSize={{ lg: '16px', xs: '12px' }}>{package_name}</Typography>
        <Stack direction="row">
          <IconButton
            onClick={() => {
              router.push(paths.clinic_dashboard.edit_package.replace(':packageId', id));
            }}
          >
            <Iconify icon="tabler:edit" />
          </IconButton>
          <DeleteDialog packageId={id} />
        </Stack>
      </Stack>

      {/* Conditionally render the Publish or Unpublish button */}
      {published === 'true' ? (
        <Button
          variant="outlined"
          onClick={onClickUnpublish}
          sx={{
            width: 1,
            borderColor: '#D92D7F',
            color: '#D92D7F',
            fontSize: { lg: '16px', xs: '12px' },
          }}
        >
          Unpublish
        </Button>
      ) : (
        <Button
          variant="outlined"
          onClick={onClickPublish}
          sx={{
            width: 1,
            color: 'white',
            boxShadow: 10,
            background: '#D92D7F',
            ':hover': { background: '#D92D7F' },
            fontSize: { lg: '16px', xs: '12px' },
          }}
        >
          Publish
        </Button>
      )}
    </Box>
  );
}
