import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import ClinicAuthGuard from 'src/auth/guard/clinic-auth-guard';
// layouts
import ClinicDashboardLayout from 'src/layouts/clinic-dashboard/layout';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import ClinicBusinessProfilePage from 'src/pages/clinic-dashboard/business-profile/clinic-business-profile';
import ClinicBusinessProfileEditPage from 'src/pages/clinic-dashboard/business-profile/clinic-business-profile-edit-page';
import ClinicAddPackagePage from 'src/pages/clinic-dashboard/packages/clinic-package-add-page';
import ClinicPackagesPage from 'src/pages/clinic-dashboard/packages/clinic-packages-page';
import ClinicDoctorPage from 'src/pages/clinic-dashboard/doctors/clinic-doctor-page';
import AddClinicDoctorPage from 'src/pages/clinic-dashboard/doctors/add-clinic-doctor-page';
import ClinicEditPackagePage from 'src/pages/clinic-dashboard/packages/clinic-package-edit-page';
import EnquiryDetailsPage from 'src/sections/dashboard/enquires/enquiry-details';
import EnquiryListView from 'src/sections/dashboard/enquires/enquiry-list-view';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/clinic-dashboard/clinic-dashboard-home'));
const PageClinicList = lazy(() => import('src/pages/dashboard/clinic-list'));

// ----------------------------------------------------------------------

export const clinicDashboardRoutes = [
  {
    path: 'clinic-dashboard',
    element: (
      <ClinicAuthGuard>
        <ClinicDashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </ClinicDashboardLayout>
      </ClinicAuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'clinic-profile', element: <PageClinicList /> },
      { path: 'business-profile', element: <ClinicBusinessProfilePage /> },
      { path: 'business-profile/edit', element: <ClinicBusinessProfileEditPage /> },
      { path: 'packages', element: <ClinicPackagesPage /> },
      { path: 'packages/add', element: <ClinicAddPackagePage /> },
      { path: 'packages/:packageId/edit', element: <ClinicEditPackagePage /> },
      { path: 'doctors', element: <ClinicDoctorPage /> },
      { path: 'doctors/add', element: <AddClinicDoctorPage /> },
      { path: 'enquires', element: <EnquiryListView />},
      { path: 'enquires/:enquiryId', element: <EnquiryDetailsPage />},
    ],
  },
];
