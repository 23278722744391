// ----------------------------------------------------------------------

import { list } from 'src/theme/overrides/components/list';

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  CLINIC_DASHBOARD: '/clinic-dashboard',
  DOCTOR_DASHBOARD: '/doctor-dashboard',
  HOME: '/',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  home: {
    root: ROOTS.HOME,
  },
  auth: {
    jwt: {
      login_select: `${ROOTS.AUTH}/jwt/login-select`,
      clinic_login: `${ROOTS.AUTH}/jwt/clinic-login`,
      doctor_login: `${ROOTS.AUTH}/jwt/doctor-login`,
      admin_login: `${ROOTS.AUTH}/jwt/admin-login`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    home: `${ROOTS.DASHBOARD}/home`,
    clinic_list: `${ROOTS.DASHBOARD}/clinic-list`,
    doctor_list: `${ROOTS.DASHBOARD}/doctor-list`,
    staff_list: `${ROOTS.DASHBOARD}/staff-list`,
    staff: {
      list: `${ROOTS.DASHBOARD}/staff-list?page=0&limit=10`,
      add: `${ROOTS.DASHBOARD}/staff-add`,
    },
    clinic: {
      list: `${ROOTS.DASHBOARD}/clinic-list`,
      add: `${ROOTS.DASHBOARD}/clinic-add`,
      details: `${ROOTS.DASHBOARD}/clinic/:id`,
    },
    doctors: {
      list: `${ROOTS.DASHBOARD}/doctors-list`,
    },
    procedures: {
      list: `${ROOTS.DASHBOARD}/procedures-list`,
      add: `${ROOTS.DASHBOARD}/procedures/add`,
      edit: `${ROOTS.DASHBOARD}/procedures/:procedureId/edit`,
    },
    enquiry: {
      list: `${ROOTS.DASHBOARD}/enquiry-list`,
      view: `${ROOTS.DASHBOARD}/enquires/:enquiryId`
    },
    treatments: {
      list: `${ROOTS.DASHBOARD}/treatments-list`,
    }
  },
  // CLINIC DASHBOARD
  clinic_dashboard: {
    root: ROOTS.CLINIC_DASHBOARD,
    home: `${ROOTS.CLINIC_DASHBOARD}/home`,
    business_profile: `${ROOTS.CLINIC_DASHBOARD}/business-profile`,
    edit_business_profile: `${ROOTS.CLINIC_DASHBOARD}/business-profile/edit`,
    packages: `${ROOTS.CLINIC_DASHBOARD}/packages`,
    add_package: `${ROOTS.CLINIC_DASHBOARD}/packages/add`,
    edit_package: `${ROOTS.CLINIC_DASHBOARD}/packages/:packageId/edit`,
    doctors: `${ROOTS.CLINIC_DASHBOARD}/doctors`,
    add_doctors: `${ROOTS.CLINIC_DASHBOARD}/doctors/add?user_id=user_id_value`,
    enquires: `${ROOTS.CLINIC_DASHBOARD}/enquires`,
    enquriy_details: `${ROOTS.CLINIC_DASHBOARD}/enquires/:enquiryId`
  },
  // DOCTOR DASHBOARD
  doctor_dashboard: {
    root: ROOTS.DOCTOR_DASHBOARD,
    home: `${ROOTS.DOCTOR_DASHBOARD}/home`,
    profile: `${ROOTS.DOCTOR_DASHBOARD}/profile`,
    edit_profile: `${ROOTS.DOCTOR_DASHBOARD}/profile/edit`,
  },
};
