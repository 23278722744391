import { alpha, Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import Logo from 'src/components/logo';
import { useSettingsContext } from 'src/components/settings';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';

export default function BusinessLandingPageView() {
  const settings = useSettingsContext();
  const router = useRouter();

  return (
    <Container
      sx={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      maxWidth={settings.themeStretch ? false : 'xl'}
    >
      <Grid
        container
        spacing={3}
        alignItems="center"
        display={{ xs: 'none', lg: 'flex' }}
        justifyContent="center"
      >
        <Grid item xs={12} md={6}>
          <Stack
            direction="column"
            alignItems={{ lg: 'flex-start', xs: 'center' }}
            sx={{ width: '100%', textAlign: { lg: 'left', xs: 'center' } }}
            spacing={3}
          >
            <Typography variant="h2" sx={{ lineHeight: 1.2 }}>
              Transform Lives,
              <br /> One client at a Time
            </Typography>
            <Typography maxWidth={400}>
              Expand your aesthetic practice, connect with new clients, and easily manage bookings
              across multiple businesses
            </Typography>
            <Stack direction="row" gap="14px">
              <Button
                sx={{ color: '#D92D7F', border: '1px solid #D92D7F', px: 3 }}
                variant="outlined"
                onClick={() => {
                  router.push('/clinic-dashboard');
                }}
              >
                Business Login
              </Button>
              <Button
                sx={{
                  background: '#D92D7F',
                  ':hover': { background: '#D92D7F' },
                  color: 'white',
                  px: 3,
                }}
                onClick={() => {
                  router.push('/doctor-dashboard');
                }}
              >
                Doctor Login
              </Button>
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={6}>
              <img
                src="/assets/images/home/hero/hero_1.png"
                alt="Hero 1"
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={2} alignItems="center">
                <img
                  src="/assets/images/home/hero/hero_2.png"
                  alt="Hero 2"
                  style={{ width: '100%' }}
                />
                <img
                  src="/assets/images/home/hero/hero_3.png"
                  alt="Hero 3"
                  style={{ width: '100%', maxWidth: '236px' }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Stack
        container
        spacing={4}
        direction="column"
        alignItems="center"
        display={{ lg: 'none' }}
        justifyContent="center"
      >
        <Logo />
        <Typography textAlign="center" fontSize="18px">
          Welcome to Cosma, Join a thriving community of beauty professionals and grow your
          business.
        </Typography>
        <Carousel
          height={400}
          sx={{ width: 1, alignItems: 'center' }}
          IndicatorIcon={<div />} // Previous Example
          indicatorIconButtonProps={{
            style: {
              width: '22px',
              height: '7px',
              borderRadius: '9px',
              marginRight: '10px',
              border: '1px solid #D92D7F',
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: '#D92D7F', // 2
            },
          }}
        >
          <img
            src="/assets/images/home/hero/mobile_hero_1.png"
            alt="mobile hero"
            style={{ height: '380px', width: '237px', margin: 'auto', display: 'block' }}
          />
          <img
            src="/assets/images/home/hero/mobile_hero_1.png"
            alt="mobile hero"
            style={{ height: '380px', width: '237px', margin: 'auto', display: 'block' }}
          />
        </Carousel>
        <Stack direction="row" gap="14px" sx={{ width: 1 }}>
          <Button
            sx={{ color: '#D92D7F', border: '1px solid #D92D7F', px: 3, py: 1, width: 1 }}
            variant="outlined"
            onClick={() => {
              router.push(paths.clinic_dashboard.root);
            }}
          >
            Business Login
          </Button>
          <Button
            sx={{
              background: '#D92D7F',
              ':hover': { background: '#D92D7F' },
              boxShadow: 10,
              width: 1,
              color: 'white',
              px: 3,
            }}
            onClick={() => {
              router.push(paths.doctor_dashboard.root);
            }}
          >
            Doctor Login
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}
