// @mui
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import {
  Button,
  Stack,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  emptyRows,
  useTable,
} from 'src/components/table';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { usePathname } from 'src/routes/hooks';
import { useAuthContext } from 'src/auth/hooks';
import { getClinicEnquires, getEnquires } from 'src/services/enquiry.service';
import EnquiryTableRow from './enquiry-table-row';


// ----------------------------------------------------------------------

const ENQUIRY_LIST_TABLE_HEAD = [
  { id: 'person_name', label: 'Person Name' },
  { id: 'phone_number', label: 'Phone Number' },
  { id: 'created_on', label: 'Created On' },
  { id: 'area_of_concern', label: 'Area of Concern' },
  { id: 'status', label: 'Status' },
  { id: 'action', label: 'Actions' },
];

const statusOptions = [
  { value: '', label: 'All' },
  { value: 'open', label: 'Open' },
  { value: 'assigned_to_clinic', label: 'Assigned To Clinic' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'ready_to_buy', label: 'Ready To Buy' },
  { value: 'free_consultation', label: 'Free Consultation' },
  { value: 'package_sold', label: 'Package Sold' },
];

const defaultFilters = {
  status: '',
};

export default function EnquiryListView() {
  const settings = useSettingsContext();
  const { user } = useAuthContext();
  const [tableData, setTableData] = useState([]);
  const [totalProcedures, setTotalProcedures] = useState(0);
  const [filters, setFilters] = useState(defaultFilters);
  const [totalRows, setTotalRows] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const path = usePathname();
  const splitPath = path.slice(1).split('/');

  const isDashboard = splitPath[0] === 'dashboard';

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const defaultSortBy = 'procedure_name';

  // hooks
  const table = useTable({
    defaultRowsPerPage: 10,
    order: defaultSortBy.split('_')[1] || 'desc',
  });

  const denseHeight = table.dense ? 56 : 76;

  const canReset = !!filters.procedure_name || !!filters.status;

  const handleChangePage = (event, newPage) => {
    table.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    table.setRowsPerPage(parseInt(event.target.value, 10));
    table.setPage(0); // Reset to the first page when changing rows per page
  };

  const notFound = (!tableData.length && canReset) || !tableData.length;

  useEffect(() => {
    if (isDashboard) {
      getEnquires(table.page + 1, table.rowsPerPage, filters.status)
        .then((enquiresList) => {
          if (enquiresList && enquiresList.results) {
            const tempTableData = enquiresList.results.map((enquiry) => ({
              enquiry_id: enquiry.id,
              customer_name: enquiry.name,
              area_of_concern: enquiry.area_of_concern,
              phone_number: enquiry.phone_number,
              message: enquiry.message,
              status: enquiry.status,
              createdOn: enquiry.created_on,
            }));
            setTableData(tempTableData);
            setTotalProcedures(enquiresList.totalResults);
          }
        })
        .catch((error) => {
          console.log('error = ', error);
        });
    } else {
      getClinicEnquires(table.page + 1, table.rowsPerPage, filters.status, user.clinic_id)
        .then((enquiresList) => {
          if (enquiresList && enquiresList.results) {
            const tempTableData = enquiresList.results.map((enquiry) => ({
              enquiry_id: enquiry.id,
              customer_name: enquiry.name,
              area_of_concern: enquiry.area_of_concern,
              phone_number: enquiry.phone_number,
              message: enquiry.message,
              status: enquiry.status,
              createdOn: enquiry.created_on,
            }));
            setTableData(tempTableData);
            setTotalProcedures(enquiresList.totalResults);
          }
        })
        .catch((error) => {
          console.log('error = ', error);
        });
    }
  }, [table.page, table.rowsPerPage, filters, user.clinic_id, isDashboard]);

  const handleViewRow = (id) => {
    console.log('view row clicked', id);
  };

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb="28px">
        <Typography variant="h4"> Enquiry List </Typography>

        {/* Status Filter */}
        <FormControl variant="outlined" sx={{ minWidth: 120 }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={filters.status || ''}
            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
            label="Status"
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar>
          <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
            <TableHeadCustom
              order={table.order}
              orderBy={table.orderBy}
              headLabel={ENQUIRY_LIST_TABLE_HEAD}
              rowCount={totalRows}
              onSort={table.onSort}
            />

            <TableBody>
              {tableData.map((row) => (
                <EnquiryTableRow
                  key={row.id}
                  row={row}
                  tablePage={table.page}
                  onViewRow={() => handleViewRow(row.id)}
                />
              ))}
              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={totalProcedures}
        page={table.page}
        rowsPerPage={table.rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        dense={table.dense}
        onChangeDense={table.onChangeDense}
        backIconButtonProps={{
          disabled: table.page === 0, // disable back button if on first page
        }}
        nextIconButtonProps={{
          disabled: table.page >= Math.ceil(totalProcedures / table.rowsPerPage) - 1, // disable next button if on last page
        }}
      />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
