import { Helmet } from 'react-helmet-async';
// sections
import ClinicBusinessProfileView from 'src/sections/clinic-dashboard/business-profile/clinic-business-profile-view';

// ----------------------------------------------------------------------

export default function ClinicBusinessProfilePage() {
  return (
    <>
      <Helmet>
        <title> Clinic Business Profile Page</title>
      </Helmet>

      <ClinicBusinessProfileView />
    </>
  );
}
