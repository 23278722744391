import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useNavigate } from 'react-router';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Snackbar, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import Iconify from 'src/components/iconify';
import { checkClinicDoctor, createClinic } from 'src/services/clinic.service';
import { useAuthContext } from 'src/auth/hooks';
import { paths } from 'src/routes/paths';

export default function AddNewDoctor() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { user } = useAuthContext();
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State to control Snackbar visibility
  const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity

  const form = useForm({
    defaultValues: {
      doctorMobileNumber: '',
    },
  });

  const { register, handleSubmit, formState } = form;
  const { errors } = formState;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const onSubmit = (data) => {
    console.log(data);
    checkClinicDoctor(user.clinic_id, data)
      .then((details) => {
        console.log('Doctor details = ', details);
        if(details.doctorAdded){
          setSnackbarMessage('Doctor is added to the clinic!');
        setSnackbarOpen(true);
        } else {
          navigate(paths.clinic_dashboard.add_doctors.replace("user_id_value",details.user_id));
        }
      })
      .catch((err) => {
        console.log('Error creating clinic = ', err);
        setSnackbarMessage('An error occurred while checking for doctor!');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };
  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<Iconify icon="tabler:plus" />}
      >
        Doctor
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Doctor</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              autoFocus
              margin="dense"
              id="doctorMobileNumber"
              label="Doctor Mobile Number"
              required
              {...register('doctorMobileNumber', { required: 'Doctor Mobile Number is required' })}
              fullWidth
              variant="outlined"
              error={!!errors.brand}
              helperText={errors.brand?.message}
            />

            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Next</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
