import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// JWT
const ClinicLoginPage = lazy(() => import('src/pages/auth/jwt/clinic-login'));
const DoctorLoginPage = lazy(() => import('src/pages/auth/jwt/doctor-login'));
const AdminLoginPage = lazy(() => import('src/pages/auth/jwt/admin-login'));
const JwtLoginDashboardTypePage = lazy(() => import('src/pages/auth/jwt/login-dashboard-type'));

// ----------------------------------------------------------------------

const authJwt = {
  path: 'jwt',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  children: [
    {
      path: 'login-select',
      element: (
        <AuthClassicLayout>
          <JwtLoginDashboardTypePage />
        </AuthClassicLayout>
      ),
    }
   ,
    {
      path: 'clinic-login',
      element: (
        <AuthClassicLayout>
          <ClinicLoginPage />
        </AuthClassicLayout>
      ),
    }, 
    {
      path: 'doctor-login',
      element: (
        <AuthClassicLayout>
          <DoctorLoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'admin-login',
      element: (
        <AuthClassicLayout>
          <AdminLoginPage />
        </AuthClassicLayout>
      ),
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authJwt],
  },
];
