import { Button, Container, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from 'src/components/table';
import { useAuthContext } from 'src/auth/hooks';
import { useSettingsContext } from 'src/components/settings';
import { getPackages } from 'src/services/package.service';
import PackageCard from 'src/components/package-card/package-card';
import AddPackageCard from 'src/components/package-card/add-package-card';

export default function ClinicPackagesView() {
  const navigate = useNavigate();
  const settings = useSettingsContext();
  const { user } = useAuthContext();
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    getPackages(user.clinic_id)
      .then((packagesList) => {
        console.log(packagesList);
        if (packagesList) {
          const tempCardData = packagesList.map((packages_item) => ({
            id: packages_item.id,
            package_name: `${packages_item.package_name}`,
            package_promo_image: packages_item.package_promo_image,
            published: `${packages_item.published}`,
          }));
          setCardData(tempCardData);
        }
      })
      .catch((error) => {
        console.log('Error fetching packages:', error);
      });
  }, [user.clinic_id]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'} sx={{ marginY: '24px' }}>
      <Grid container spacing="18px">
        <Grid item lg={4} xl={3} xs={6}>
          <AddPackageCard />
        </Grid>
        {cardData.map((cardDetails) => (
          <Grid item xs={6} key={cardDetails.id} lg={4} xl={3}>
            <PackageCard cardDetails={cardDetails} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
