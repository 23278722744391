import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import AdminAuthGuard from 'src/auth/guard/admin-auth-guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import EditProcedureView from 'src/sections/dashboard/procedures/edit-procedure-view';


// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/home'));
const PageClinicList= lazy(() => import('src/pages/dashboard/clinic-list'));
const PageDoctorList= lazy(() => import('src/pages/dashboard/doctor-list'));
const PageStaffList= lazy(() => import('src/pages/dashboard/staff-list'));
const PageClinicDetails= lazy(() => import('src/pages/dashboard/clinic/clinic-details'));
const PageProceduresList= lazy(() => import('src/pages/dashboard/procedures-list'));
const PageEditProcedure = lazy(() => import('src/pages/dashboard/procedures/edit-procedure-page'));
const PageEnquiryList = lazy(() => import('src/pages/dashboard/enquiry-list'));
const PageEnquiryDetails = lazy(() => import('src/pages/dashboard/enquiries/enquiry-details-page'));
const PageTreatmentsList = lazy(() => import('src/pages/dashboard/treatments-list'));
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AdminAuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AdminAuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'clinic-list', element: <PageClinicList />},
      { path: 'doctor-list', element: <PageDoctorList />},
      { path: 'staff-list', element: <PageStaffList />},
      { path: 'clinic/:id', element: <PageClinicDetails />},
      { path: 'procedures-list', element: <PageProceduresList />},
      { path: 'procedures/:procedureId/edit', element: <PageEditProcedure />},
      {path: 'enquiry-list', element: <PageEnquiryList />},
      {path: 'enquires/:enquiryId', element: <PageEnquiryDetails />},
      { path: 'treatments-list', element: <PageTreatmentsList />}
    ],
  },
];
