import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  FormHelperText,
  Snackbar,
  Alert,
  CircularProgress,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthContext } from 'src/auth/hooks';
import Iconify from 'src/components/iconify';
import { useRouter } from 'src/routes/hooks';
import { getClinics } from 'src/services/clinic.service';
import { addEnquiryComment, updateEnquiry } from 'src/services/enquiry.service';

export default function EditEnquiryPopup({ fieldName, fieldContent, options, enquiryId }) {
  const [open, setOpen] = useState(false);
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [clinics, setClinics] = useState([]);

  const form = useForm({
    defaultValues: {
      [fieldName]: fieldContent,
    },
  });

  const router = useRouter();

  const { register, handleSubmit, setValue, formState, watch } = form;
  const { errors } = formState;
  const selectedField = watch(fieldName);

  useEffect(() => {
    if (fieldName === 'assigned_to') {
      getClinics()
        .then((c) => {
          setClinics(c.results);
        })
        .catch(() => {
          console.error('Failed to fetch clinics');
        });
    }
  }, [fieldName]);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const onSubmit = (data) => {
    setLoading(true);
    const action =
      fieldName !== 'comments'
        ? updateEnquiry(enquiryId, data)
        : addEnquiryComment({
            enquiry_id: enquiryId,
            comment: data.comments,
            user_id: user.user_id,
          });

    action
      .then(() => {
        setLoading(false);
        setSnackbarMessage(`${fieldName} updated successfully!`);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        handleClose();
        router.reload();
      })
      .catch(() => {
        setLoading(false);
        setSnackbarMessage(`Failed to update ${fieldName}.`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  return (
    <div>
      <Button variant="text" onClick={handleClickOpen} startIcon={<Iconify icon="tabler:edit" />}>
        {fieldName === 'comments' ? 'Add' : 'Edit'} {fieldName}
      </Button>
      <Dialog open={open} onClose={handleClose} sx={{ margin: '20px' }} fullWidth>
        <DialogTitle>
          {fieldName === 'comments' ? 'Add' : 'Edit'} {fieldName}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            {fieldName === 'status' && (
              <FormControl fullWidth margin="dense" error={!!errors.status}>
                <InputLabel id="status-select-label">Select Status</InputLabel>
                <Select
                  value={selectedField || ''}
                  name="status"
                  id="status"
                  labelId="status-select-label"
                  label="Select Status"
                  onChange={(e) => setValue('status', e.target.value)}
                  {...register('status', { required: 'Status is required' })}
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.status && <FormHelperText>{errors.status?.message}</FormHelperText>}
              </FormControl>
            )}

            {fieldName === 'comments' && (
              <TextField
                margin="dense"
                id="comments"
                label="Comments"
                multiline
                rows={4}
                {...register('comments', { required: 'Comments are required' })}
                fullWidth
                variant="outlined"
              />
            )}

            {fieldName === 'assigned_to' && (
              <FormControl fullWidth margin="dense" error={!!errors.assigned_to}>
                <InputLabel id="assigned-select-label">Select Clinic</InputLabel>
                <Select
                  value={selectedField || ''}
                  name="assigned_to"
                  id="assigned_to"
                  labelId="assigned-select-label"
                  label="Assign to Clinic"
                  onChange={(e) => setValue('assigned_to', e.target.value)}
                  {...register('assigned_to', { required: 'Please assign a clinic' })}
                >
                  {clinics.map((clinic) => (
                    <MenuItem key={clinic.id} value={clinic.id}>
                      {clinic.legal_name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.assigned_to && (
                  <FormHelperText>{errors.assigned_to?.message}</FormHelperText>
                )}
              </FormControl>
            )}

            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 2, mb: 4 }}
              disabled={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
              {loading ? 'Saving...' : 'Save'}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
