import { LoadingButton } from '@mui/lab';
import { alpha, Box, Button, Chip, Container, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuthContext } from 'src/auth/hooks';
import { RHFTextField } from 'src/components/hook-form';
import Image from 'src/components/image';
import { LoadingScreen } from 'src/components/loading-screen';
import { useSettingsContext } from 'src/components/settings';
import { getClinicDetails } from 'src/services/clinic.service';

export default function ClinicBusinessProfileView() {
  const settings = useSettingsContext();
  const { user } = useAuthContext();
  const [clinicData, setClinicData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  console.log('clinicData = ', clinicData);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getClinicDetails(user.clinic_id)
      .then((data) => {
        setClinicData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user]);

  const ClinicInfoItem = ({ title, value }) => (
    <div className="flex-grow">
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body1">{value}</Typography>
    </div>
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Stack direction="row" justifyContent="space-between" sx={{ marginBottom: '14px' }}>
        <Typography variant="h4"> Clinic Business Profile </Typography>
        <Button
          variant="outlined"
          onClick={() => {
            navigate('/clinic-dashboard/business-profile/edit');
          }}
        >
          Edit Profile
        </Button>
      </Stack>
      {isLoading && (
        <Box
          sx={{
            minHeight: '80vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingScreen />
        </Box>
      )}
      {clinicData && (
        <Stack direction="row" gap="14px" flexWrap="wrap">
          <Grid container spacing={{ xs: '14px', lg: '44px' }}>
            <Grid item xs={12} sm={4}>
              <ClinicInfoItem title="Brand" value={clinicData?.brand} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ClinicInfoItem title="Legal name" value={clinicData?.legal_name} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ClinicInfoItem title="Unique Id" value={clinicData?.unique_id} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ClinicInfoItem
                title="Registration Number"
                value={
                  clinicData.registration_number ? clinicData.registration_number : 'To be entered'
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ClinicInfoItem
                title="GST Number"
                value={clinicData.gst_number ? clinicData.gst_number : 'To be entered'}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ClinicInfoItem
                title="PAN Number"
                value={clinicData.pan_number ? clinicData.pan_number : 'To be entered'}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ClinicInfoItem
                title="Google Business Profile"
                value={clinicData.pan_number ? clinicData.pan_number : 'To be entered'}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ClinicInfoItem
                title="Adress"
                value={
                  clinicData.address
                    ? `${clinicData.address.city}, ${clinicData.address.state},${clinicData.address.country}`
                    : 'To be entered'
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Brand Logo</Typography>
              {clinicData.logo_image ? (
                <img src={clinicData.logo_image} alt="brand-logo" />
              ) : (
                <Typography>To be Uploaded</Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Institute Category</Typography>
              {console.log(clinicData.institute_category)}
              {clinicData.institute_category.length > 0 ? (
                <Stack direction="row" gap={2}>
                  {clinicData.institute_category.map((institute, index) => (
                    <Chip key={index} label={institute} />
                  ))}
                </Stack>
              ) : (
                <Typography>To be added</Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Services Provided</Typography>
              {clinicData.services_provided.length > 0 ? (
                <Stack direction="row" gap={2}>
                  {clinicData.services_provided.map((service, index) => (
                    <Chip key={index} label={service} />
                  ))}
                </Stack>
              ) : (
                <Typography>To be added</Typography>
              )}
            </Grid>
          </Grid>
        </Stack>
      )}
    </Container>
  );
}
