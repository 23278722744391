import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuthContext } from 'src/auth/hooks';
import { LoadingScreen } from 'src/components/loading-screen';
import { useSettingsContext } from 'src/components/settings';
import UpdateUserPassword from 'src/sections/clinics/update-user-password';
import { getDoctorDetails } from 'src/services/doctor.service';

const DoctorProfileView = () => {
  const settings = useSettingsContext();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [doctorData, setDoctorData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getDoctorDetails(user.user_id)
      .then((data) => {
        console.log(data);
        setDoctorData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user]);

  // Reusable component to display single info item
  const DoctorInfoItem = ({ title, value }) => (
    <div className="flex-grow">
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body1">{value || 'To be added'}</Typography>
    </div>
  );

  // Reusable component to display list of items
  const DoctorInfoList = ({ title, items }) => (
    <div>
      <Typography variant="h6">{title}</Typography>
      {items && items.length > 0 ? (
        <ul>
          {items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      ) : (
        <Typography variant="body1">To be added</Typography>
      )}
    </div>
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Stack direction="row" justifyContent="space-between" sx={{ marginY: '14px' }}>
        <Typography variant="h4"> Doctor Profile </Typography>
        <Stack direction="row" gap="10px">
          <Button
            variant="outlined"
            onClick={() => {
              navigate('/doctor-dashboard/profile/edit');
            }}
          >
            Edit Profile
          </Button>
          {doctorData && <UpdateUserPassword email={doctorData.userDetails.email} />}
        </Stack>
      </Stack>

      {isLoading && (
        <Box
          sx={{
            minHeight: '80vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingScreen />
        </Box>
      )}

      {doctorData && (
        <Stack direction="row" gap="14px" flexWrap="wrap">
          <Grid container spacing={{ xs: '14px', lg: '44px' }}>
            <Grid item xs={12} lg={4}>
              <DoctorInfoItem
                title="Name"
                value={`${doctorData.userDetails.first_name} ${doctorData.userDetails.last_name}`}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DoctorInfoItem title="Email" value={doctorData.userDetails.email} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DoctorInfoItem
                title="Mobile Number"
                value={`${doctorData.userDetails.telephone_country_code} ${doctorData.userDetails.telephone_number}`}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DoctorInfoItem
                title="Registration Number"
                value={doctorData.doctorDetails.registration_number}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DoctorInfoItem title="Experience" value={doctorData.doctorDetails.experience} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DoctorInfoItem
                title="Qualification"
                value={doctorData.doctorDetails.qualification}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <DoctorInfoList title="Education" items={doctorData.doctorDetails.education} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DoctorInfoList title="Certificates" items={doctorData.doctorDetails.certificates} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DoctorInfoList
                title="Specialization"
                items={doctorData.doctorDetails.specialization}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DoctorInfoList
                title="Super Specialization"
                items={doctorData.doctorDetails.super_specialization}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DoctorInfoList title="Fellowship" items={doctorData.doctorDetails.fellowship} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DoctorInfoList title="Awards" items={doctorData.doctorDetails.awards} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DoctorInfoList
                title="Services Provided"
                items={doctorData.doctorDetails.services_provided}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Typography variant="h6">Profile Image</Typography>
              {doctorData.doctorDetails.profile_photo ? (
                <img src={doctorData.doctorDetails.profile_photo} alt="brand-logo" />
              ) : (
                <Typography>To be Uploaded</Typography>
              )}
            </Grid>
          </Grid>
        </Stack>
      )}
    </Container>
  );
};

export default DoctorProfileView;
