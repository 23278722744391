export const instituteCategoriesAndServices = {
  'Dermatology & Cosmatology': [
    'Acne Blue Light Therapy',
    'Aquagold Fine Touch',
    'BBL (BroadBand Light Therapy)',
    'Botox',
    'Chemical Peels',
    'Dermabrasion',
    'Dermal Fillers',
    'Diamond Glow',
    'HIFU (High-Intensity Focused Ultrasound)',
    'Hydrafacial',
    'IPL (Intense Pulse Light) Therapy',
    'Microneedling',
    'PRP (Platelet Rich Plasma) Therapy',
    'Profhilo',
    'Radiofrequency Skin Tightening',
    'Silhouette Instalift',
    'Skin Boosters',
    'Ultherapy',
    'Laser Skin Resurfacing',
    'Laser Melasma Treatment',
    'Laser Pigmentation Removal',
    'Keloid Treatments',
    'Laser Vein Removal',
    'Thread Vein Removal',
    'Jaw Slimming Injections',
    'Facial Cupping',
    'Facial Steaming',
    'Facial Reflexology',
    'Photodynamic Therapy (PDT)',
    'Lip Augmentation (Non-Surgical)',
    'Cryolipolysis (CoolSculpting)',
    'CoolTone',
    'Ultrasound Cavitation',
    'Body Wraps',
    'Body Scrubs',
    'Cellulite Treatment',
    'Endermologie',
    'VelaShape',
    'Gua Sha',
    'Hot Stone Massage',
    'Lymphatic Drainage Massage',
    'O-Shot (Orgasm Shot)',
    'Hair Transplant (Follicular Unit Extraction - FUE)',
    'Scalp Micropigmentation (SMP)',
    'Brazilian Blowout',
    'Hair Coloring and Highlights',
    'Hair Perms',
    'Keratin Treatment',
    'Low-Level Laser Therapy (LLLT)',
    'Oral Finasteride (Propecia)',
    'Trichology Treatments',
    'Neck Electromagnetic Therapy',
    'Neck Hydrofacial',
    'Neck Mesotherapy',
    'Neck Microcurrent Therapy',
    'Neck Oxygen Therapy',
    'Neck Skin Resurfacing',
    'Foot Callus Removal',
    'Foot Corn Removal',
    'Foot Fungal Nail Treatment',
    'Foot Wart Removal',
    'Skin Tag Removal',
    'Mole Removal',
    'Topical Scar Treatments',
    'Eczema Treatments',
    'Psoriasis Treatments',
    'Hives and Allergic Reaction Treatments',
    'Vitiligo Treatments',
    'Hyperhidrosis Treatment',
  ],

  'Plastic Surgery': [
    'Scar Revision Surgery',
    'Buccal Fat Removal',
    'Cheek Augmentation',
    'Jawline Contouring Surgery',
    'Genioplasty (Chin Augmentation)',
    'Midface Lift',
    'Rhinoplasty',
    'Otoplasty (Ear Pinning)',
    'Blepharoplasty (Eyelid Surgery)',
    'Browplasty (Forehead Lift)',
    'Rhytidectomy (Facelift)',
    'Temporal Lift',
    'Thread Lift',
    'Forehead Reduction',
    'Facial Feminization Surgery (FFS)',
    'Dimpleplasty',
    'Liposuction (Facial Area)',
    'Zygomatic Osteotomy',
    'Liposuction (Various Areas)',
    'Arm Liposculpture',
    'Ankle Liposuction',
    'Knee Liposuction',
    'Abdominoplasty (Tummy Tuck)',
    'Lower Body Lift',
    'Upper Body Lift',
    'Panniculectomy',
    'Reverse Tummy Tuck',
    'Fleur-de-Lis Tummy Tuck',
    'Circumferential Abdominoplasty',
    'Diastasis Recti Repair',
    'Thighplasty',
    'Breast Augmentation (Implants)',
    'Augmentation Mammoplasty',
    'Mastopexy (Breast Lift)',
    'Reduction Mammoplasty',
    'Breast Reconstruction',
    'Tuberous Breast Correction',
    'Inverted Nipple Correction',
    'Gynecomastia Surgery (Male Breast Reduction)',
    'Breast Liposuction',
    'Benelli Mastopexy (Donut Lift)',
    'Brazilian Butt Lift (BBL)',
    'Buttock Augmentation',
    'Buttock Enhancement Injections',
    'Buttock Implants',
    'Buttock Lift (Gluteoplasty)',
    'Buttock Volume Restoration',
    'Vaginal Rejuvenation',
    'Labiaplasty',
    'Clitoral Hood Reduction',
    'Hymenoplasty',
    'Vaginoplasty',
    'Perineoplasty',
    'Penile Enlargement Surgery',
    'Penile Prosthesis',
    "Penile Straightening (Peyronie's Disease Surgery)",
    'Frenuloplasty',
    'Scrotal Lift',
    'Testicular Implants',
    'Gender Affirmation Surgery',
    'Neck Contouring Surgery',
    'Platysmaplasty (Neck Lift)',
    'Cervicoplasty (Neck Lift)',
    'Achilles Tendon Surgery',
    'Bunion Surgery (Bunionectomy)',
    'Foot Amputation',
    'Foot Reconstruction Surgery',
    'Foot Scar Revision Surgery',
    'Hammertoe Surgery',
    'Heel Spur Removal Surgery',
    'Metatarsal Surgery',
    'Plantar Fasciitis Surgery',
    'Tarsal Tunnel Release Surgery',
    'Toe Joint Fusion Surgery',
    'Toe Joint Replacement Surgery',
    'Toe Shortening Surgery',
    'Toe Straightening Surgery',
    'Toenail Surgery',
    'Varicose Vein Surgery',
    'Leg Lengthening Surgery',
    'Leg Amputation',
    'Skin Cancer Removal',
  ],
};
