import { getData, putData } from 'src/utils/axios';
import { urlMap } from 'src/utils/urls';

export const getDoctorDetails = async (userId) => {
  const url = `${urlMap.doctors.getDetails.replace(':userId', userId)}`;
  return getData(url);
};

export const updateDoctorDetails = async (userId, updatedDetails) => {
  const url = `${urlMap.doctors.update.replace(':userId', userId)}`;
  return putData(url,updatedDetails);
};


export const getDoctors = async (userId) => {
  const url = `${urlMap.doctors.getAll}`;
  return getData(url);
};
