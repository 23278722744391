import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Stack,
  Typography,
  FormControl,
  Snackbar,
  Alert,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useAuthContext } from 'src/auth/hooks';
import { LoadingScreen } from 'src/components/loading-screen';
import { useSettingsContext } from 'src/components/settings';
import { getClinicDetails, updateClinicDetails } from 'src/services/clinic.service';
import RHFImageUpload from 'src/components/hook-form/rhf-image-upload';
import { Upload, UploadBox } from 'src/components/upload';
import { instituteCategoriesAndServices } from 'src/utils/institutecategoriesandServices';

export default function ClinicBusinessProfileEdit() {
  const settings = useSettingsContext();
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State to control Snackbar visibility
  const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity

  const [availableServices, setAvailableServices] = useState([]);

  const initialDefaultValues = {
    unique_id: '',
    brand: '',
    legal_name: '',
    address: {
      city: '',
      state: '',
      zip_code: '',
      country: 'India',
    },
    logo_image: '',
    google_business_profile_url: '',
    registration_number: '',
    services_provided: [],
    institute_category: [],
    gallery: [],
    pan: '',
    gst_number: '',
    banner_image: '',
  };

  const businessProfileSchema = Yup.object().shape({
    // Add your validation schema here
    brand: Yup.string(),
    legal_name: Yup.string(),
    address: Yup.object().shape({
      city: Yup.string(),
      state: Yup.string(),
      zip_code: Yup.string(),
      country: Yup.string().required('Country is required'),
    }),
    unique_id: Yup.string().required(),
    logo_image: Yup.string(),
    google_business_profile_url: Yup.string().url(),
    registration_number: Yup.string(),
    gst_number: Yup.string(),
    services_provided: Yup.array().of(Yup.string()),
    pan: Yup.string(),
    institute_category: Yup.array().of(Yup.string()),
    gallery: Yup.array().of(Yup.string()),
    banner_image: Yup.string(),
  });

  const {
    control,
    reset,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(businessProfileSchema),
    defaultValues: initialDefaultValues,
  });

  useEffect(() => {
    getClinicDetails(user.clinic_id)
      .then((data) => {
        console.log(data);
        reset(data); // Reset form values with fetched data
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [user, reset]);

  console.log(errors);

  const logoImage = watch('logo_image');
  const bannerImage = watch('banner_image');
  const bannerImageFileName = `banner_image_${new Date().toISOString()}`;
  const bannerImageFolderName = `images/banner/${user.clinic_id}`;
  const fileName = `brand_logo_${Date.now().toString()}`;
  const folderName = `images/logo/${user.clinic_id}`;

  const instituteCategory = watch('institute_category');
  const galleryImages = watch('gallery');
  const galleryImagesFileName = `gallery_image_${new Date().toISOString()}`
  const galleryImagesFolderName = `images/${user.clinic_id}/gallery`

  console.log(galleryImages);


  // Function to update services based on selected categories
  useEffect(() => {
    if (instituteCategory && instituteCategory.length > 0) {
      const filteredServices = instituteCategory.reduce((acc, category) => {
        const services = instituteCategoriesAndServices[category];
        return acc.concat(services || []);
      }, []);
      setAvailableServices(filteredServices);
    } else {
      // Show all services if no category is selected
      const allServices = Object.values(instituteCategoriesAndServices).flat();
      setAvailableServices(allServices);
    }
  }, [instituteCategory]);

  console.log(logoImage);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const onSubmit = (data) => {
    console.log(data);
    // Handle form submission
    const finalData = {
      unique_id: data.unique_id,
      brand: data.brand,
      legal_name: data.legal_name,
      address: {
        city: data.address.city,
        state: data.address.state,
        zip_code: data.address.zip_code,
        country: data.address.country,
      },
      logo_image: data.logo_image,
      google_business_profile_url: data.google_business_profile_url,
      registration_number: data.registration_number,
      services_provided: data.services_provided,
      institute_category: data.institute_category,
      pan: data.pan,
      gst_number: data.gst_number,
      gallery: data.gallery,
      banner_image: data.banner_image,
    };
    updateClinicDetails(user.clinic_id, finalData)
      .then(() => {
        reset(data); // Reset form values with updated data
        setSnackbarMessage('Clinic business profile updated successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.log('Error:', error.message);
        setSnackbarMessage(`Error: ${error.message}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const names = ['Cosmetic', 'Dental', 'Saloon'];

  console.log(getValues());

  return (
    <Container
      sx={{
        background: { lg: 'white' },
        margin: 4,
        marginX: 'auto',
        padding: 3,
        maxWidth: 840,
        borderRadius: 4,
      }}
    >
      <Stack direction="row" gap="12px" justifyContent="center" sx={{ marginBottom: '14px' }}>
        <Typography textAlign="center" marginBottom={4} variant="h4">
          Setup Your Business Profile{' '}
        </Typography>
      </Stack>
      {isLoading ? (
        <Box
          sx={{
            minHeight: '80vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingScreen />
        </Box>
      ) : (
        <FormProvider onSubmit={handleSubmit(onSubmit)} methods={{ control, handleSubmit }}>
          <Stack direction="row" gap="14px" flexWrap="wrap">
            <Grid container spacing={{ xs: '14px', lg: '24px' }}>
              <Grid item xs={12} sm={6}>
                <RHFTextField name="brand" label="Brand Name" disabled variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RHFTextField
                  name="registration_number"
                  label="Registration Number"
                  variant="outlined"
                />
              </Grid>
              {/* Institute Category Select */}
              <Grid item xs={12} md={6} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="institute-category-label">Institute Category</InputLabel>
                  <Controller
                    name="institute_category"
                    control={control}
                    render={({ field }) => (
                      <Select
                        labelId="institute-category-label"
                        multiple
                        value={field.value || []}
                        onChange={(e) => field.onChange(e.target.value)}
                        input={<OutlinedInput label="Institute Category" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                      >
                        {Object.keys(instituteCategoriesAndServices).map((category) => (
                          <MenuItem key={category} value={category}>
                            {category}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <RHFTextField
                  name="google_business_profile_url"
                  label="Google Business Profile"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RHFTextField name="gst_number" label="GST Number" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RHFTextField name="legal_name" label="Legal Name" disabled variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RHFTextField name="pan_number" label="PAN Number" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RHFTextField name="address.city" label="Business Address" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RHFTextField name="address.state" label="Address Line 2" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RHFTextField name="address.zip_code" label="Zip Code" variant="outlined" />
              </Grid>

              {/* Conditional rendering for logo_image */}
              <Grid item xs={6}>
                <RHFImageUpload
                  initialImage={logoImage}
                  fileName={fileName}
                  setValue={setValue}
                  filesRequired={1}
                  maxFiles={1}
                  displayImages
                  name="logo_image"
                  folderName={folderName}
                  label="Upload Brand Logo"
                  width={515}
                  height={515}
                  maxHeight={515}
                  maxWidth={515}
                />
              </Grid>

              {/* <Grid item xs={12}>
                <Upload
                  title="Upload Your Documents"
                  type="manual"
                  multiple
                  inputFileTypeOptions={[
                    { value: 'pdf', label: 'PDF' },
                    { value: 'docx', label: 'DOCX' },
                  ]}
                  inputSourceFormatOptions={[{ value: 'custom', label: 'Custom Format' }]}
                  inputFileType={{ value: 'pdf', label: 'PDF' }}
                  onInputFileTypeChange={(newType) => console.log('File type changed:', newType)}
                  onDelete={() => console.log('File deleted')}
                  onRemove={() => console.log('File removed')}
                  onRemoveAll={() => console.log('All files removed')}
                  file={null}
                  files={[]}
                />
              </Grid> */}

              {/* Services Provided Select */}
              <Grid item xs={12} md={6} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="services-label">Services Provided</InputLabel>
                  <Controller
                    name="services_provided"
                    control={control}
                    render={({ field }) => (
                      <Select
                        labelId="services-label"
                        multiple
                        value={field.value || []}
                        onChange={(e) => field.onChange(e.target.value)}
                        input={<OutlinedInput label="Services Provided" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                      >
                        {availableServices.map((service) => (
                          <MenuItem key={service} value={service}>
                            {service}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <RHFImageUpload
                  initialImage={bannerImage}
                  fileName={bannerImageFileName}
                  setValue={setValue}
                  filesRequired={1}
                  maxFiles={1}
                  displayImages
                  name="banner_image"
                  folderName={bannerImageFolderName}
                  label="Upload Banner Image"
                  width={1530}
                  height={750}
                  maxHeight={750}
                  maxWidth={1530}
                />
              </Grid>

              <Grid item xs={12}>
            <RHFImageUpload
              initialImage={galleryImages}
              fileName={galleryImagesFileName}
              setValue={setValue}
              name="gallery"
              folderName={galleryImagesFolderName}
              filesRequired={1}
              displayImages
              multipleFiles
              maxFiles={10}
              label="Gallery"
              variant="outlined"
              width={500}
              height={300}
              required
            />
          </Grid>


              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    width: 1,
                    background: '#D92D7F',
                    boxShadow: 8,
                    ':hover': { background: '#D92D7F' },
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </FormProvider>
      )}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
