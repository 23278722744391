import {
  Alert,
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Iconify from 'src/components/iconify';
import FormProvider from 'src/components/hook-form/form-provider';
import { useResponsive } from 'src/hooks/use-responsive';
import { addClinicDoctor, getUserDetails } from 'src/services/clinic.service';
import { RHFTextField } from 'src/components/hook-form';
import RHFImageUpload from 'src/components/hook-form/rhf-image-upload';
import RHFTextFieldWithChips from 'src/components/hook-form/rhf-chip-text-field';
import { useAuthContext } from 'src/auth/hooks';
import { getDoctorDetails, updateDoctorDetails } from 'src/services/doctor.service';
import { LoadingScreen } from 'src/components/loading-screen';
import { paths } from 'src/routes/paths';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function DoctorProfileEditView() {
  const navigate = useNavigate();
  const lgUp = useResponsive('up', 'lg');
  const { user } = useAuthContext();
  const [searchParams] = useSearchParams();
  const [doctorDetails, setDoctorDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');

  const doctorSchema = Yup.object({
    userDetails: Yup.object({
      first_name: Yup.string().required('First name is required'),
      last_name: Yup.string(),
      telephone_number: Yup.string().required('Telephone number is required'),
      email: Yup.string().email().required('Email is required'),
    }),
    doctorDetails: Yup.object({
      profile_photo: Yup.string().url(),
      registration_number: Yup.string().required('Registration number is required'),
      services_provided: Yup.array().of(Yup.string()),
      specalization: Yup.array().of(Yup.string()),
      experience: Yup.number(),
      qualification: Yup.string(),
      super_specalization: Yup.array().of(Yup.string()),
      fellowship: Yup.array().of(Yup.string()),
      awards: Yup.array().of(Yup.string()),
      certificates: Yup.array().of(Yup.string()),
    }),
  });

  const defaultValues = {
    userDetails: {
      first_name: '',
      last_name: '',
      telephone_number: '',
      password: '',
      email: '',
    },
    doctorDetails: {
      profile_photo: '',
      registration_number: '',
      services_provided: [],
      specialization: [],
      experience: 0,
      qualification: '',
      super_specialization: [],
      fellowship: [],
      awards: [],
      certificates: [],
      education: [],
    },
  };

  const { control, reset, handleSubmit, setValue, watch } = useForm({
    resolver: yupResolver(doctorSchema),
    defaultValues,
  });

  useEffect(() => {
    getDoctorDetails(user.user_id)
      .then((data) => {
        setDoctorDetails(data);
        reset(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user.user_id, reset]);

  const fileName = `doctor_profile.${new Date().toISOString()}`;
  const folderName = `images/users`;

  const education = watch('doctorDetails.education');
  console.log(education);

  const onSubmit = (data) => {
    const finalData = {
      userDetails: {
        first_name: data.userDetails.first_name,
        last_name: data.userDetails.last_name,
        telephone_number: data.userDetails.telephone_number,
        email: data.userDetails.email,
      },
      doctorDetails: {
        profile_photo: data.doctorDetails.profile_photo,
        registration_number: data.doctorDetails.registration_number,
        services_provided: data.doctorDetails.services_provided,
        specialization: data.doctorDetails.specialization,
        experience: data.doctorDetails.experience,
        qualification: data.doctorDetails.qualification,
        super_specialization: data.doctorDetails.super_specialization,
        fellowship: data.doctorDetails.fellowship,
        awards: data.doctorDetails.awards,
        certificates: data.doctorDetails.certificates,
        education: data.doctorDetails.education,
      },
    };
    updateDoctorDetails(user.user_id, finalData)
      .then(() => {
        console.log('Doctor updated successfully');
        reset();
        navigate(paths.doctor_dashboard.profile);
      })
      .catch((error) => {
        console.error('Error adding doctor', error);
        setErrorMsg(typeof error === 'string' ? error : error.message);
      });
    console.log(finalData);
  };

  return (
    <Container
      sx={{
        background: { lg: 'white' },
        margin: { lg: 4 },
        marginX: 'auto',
        padding: { lg: 3 },
        width: '90%',
        maxWidth: 840,
        borderRadius: 4,
      }}
    >
      <Stack direction="row" gap="14px" alignItems="center" sx={{ marginBottom: '14px' }}>
        {lgUp && (
          <IconButton
            aria-label="back-button"
            size="large"
            onClick={() => {
              navigate(-1);
            }}
          >
            <Iconify icon="tabler:chevron-left" />
          </IconButton>
        )}
        <Typography variant="h4" sx={{ width: '100%' }} textAlign={{ xs: 'center', lg: 'left' }}>
          Edit Your Profile
        </Typography>
      </Stack>
      {isLoading ? (
        <Box
          sx={{
            minHeight: '80vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingScreen />
        </Box>
      ) : (
        <FormProvider onSubmit={handleSubmit(onSubmit)} methods={{ control, handleSubmit }}>
          <Grid container rowSpacing={{ xs: '14px', lg: '24px' }} columnSpacing={{ lg: '24px' }}>
            <Grid item xs={12}>
              {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
            </Grid>
            <Grid item xs={12} lg={6}>
              <RHFTextField
                name="userDetails.first_name"
                label="First Name"
                margin="dense"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <RHFTextField
                name="userDetails.last_name"
                label="Last Name"
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <RHFTextField
                name="userDetails.email"
                label="Email Address"
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <RHFTextField
                name="userDetails.telephone_number"
                label="Telephone Number"
                margin="dense"
                variant="outlined"
                required
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <RHFTextField
                name="doctorDetails.registration_number"
                label="Registration Number"
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <RHFTextField
                name="doctorDetails.experience"
                label="Experience"
                margin="dense"
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <RHFTextField
                name="doctorDetails.qualifications"
                label="Qualifications"
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <RHFTextFieldWithChips
                name="doctorDetails.services_provided"
                label="Services Provided"
                margin="dense"
                variant="outlined"
                setValue={setValue}
                initialValue={watch('doctorDetails.services_provided')}
                multiple
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <RHFTextFieldWithChips
                name="doctorDetails.specialization"
                label="Specialization"
                margin="dense"
                variant="outlined"
                setValue={setValue}
                initialValue={watch('doctorDetails.specialization')}
                multiple
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <RHFTextFieldWithChips
                name="doctorDetails.fellowship"
                label="Fellowship"
                margin="dense"
                variant="outlined"
                setValue={setValue}
                initialValue={watch('doctorDetails.fellowship')}
                multiple
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <RHFTextFieldWithChips
                name="doctorDetails.awards"
                label="Awards"
                margin="dense"
                variant="outlined"
                setValue={setValue}
                initialValue={watch('doctorDetails.awards')}
                multiple
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <RHFTextFieldWithChips
                name="doctorDetails.certificates"
                label="Certificates"
                margin="dense"
                variant="outlined"
                setValue={setValue}
                initialValue={watch('doctorDetails.certificates')}
                multiple
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <RHFTextFieldWithChips
                name="doctorDetails.education"
                label="Education Details"
                margin="dense"
                variant="outlined"
                setValue={setValue}
                initialValue={watch('doctorDetails.education')}
                multiple
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <RHFTextFieldWithChips
                name="doctorDetails.super_specialization"
                label="Super Specialization"
                margin="dense"
                variant="outlined"
                setValue={setValue}
                initialValue={watch('doctorDetails.super_specialization')}
                multiple
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <RHFImageUpload
                name="doctorDetails.profile_photo"
                fileName={fileName}
                folderName={folderName}
                label="Upload Profile Photo"
                setValue={setValue}
                displayImages
                maxFiles={1}
                width={300}
                height={300}
                maxHeight={300}
              />
            </Grid>
            <Grid container item alignItems="end" xs={12} justifyContent="flex-end">
              <Button
                variant="contained"
                sx={{
                  width: 1,
                  boxShadow: 10,
                  fontSize: { lg: '16px', xs: '12px' },
                  background: '#D92D7F',
                  ':hover': { background: '#D92D7F' },
                }}
                type="submit"
              >
                Add Doctor
              </Button>
            </Grid>
          </Grid>
        </FormProvider>
      )}
    </Container>
  );
}
