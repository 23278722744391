import { getData, postData, putData } from 'src/utils/axios';
import { urlMap } from 'src/utils/urls';

export const getBodyParts = async () => {
  const url = `${urlMap.treatments.bodyParts}`;
  return getData(url);
};

export const getProcedures = async (bodyPart) => {
  const url = `${urlMap.treatments.procedures.replace('{body_part}', bodyPart)}`;
  return getData(url);
};

export const getConcerns = async (bodyPart, procedureName) => {
  const url = `${urlMap.treatments.concerns
    .replace('{body_part}', bodyPart)
    .replace('{procedure}', procedureName)}`;
  return getData(url);
};

export const getTreatmentId = async (bodyPart, procedure, concern) => {
  const url = `${urlMap.treatments.treatmentId
    .replace('{body_part}', bodyPart)
    .replace('{procedure}', procedure)
    .replace('{concern}', concern)}`;
  return getData(url);
};

export const getProcedureName = async (treatment_id) => {
  const url = `${urlMap.treatments.procedureName.replace('{treatmentId}', treatment_id)}`;
  return getData(url);
};

export const getAllTreatments = async (pageValue = 1, limitValue = 10) => {
  const url = `${urlMap.treatments.all
    .replace('pageValue', `${pageValue}`)
    .replace('limitValue', `${limitValue}`)}`;
  return getData(url);
};


export const getSelectOptions = async () => {
  const data = `${urlMap.treatments.data}`
  return getData(data);
}

export const createTreatment = async (data) => {
  const url = `${urlMap.treatments.create}`;
  return postData(url, data);
}

export const updateTreatment = async (treatmentId, data) => {
  console.log(treatmentId);
  const url = `${urlMap.treatments.update.replace('{treatmentId}', treatmentId)}`;
  return putData(url, data);
}