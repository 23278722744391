import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import DoctorAuthGuard from 'src/auth/guard/doctor-auth-guard';
// layouts
import DoctorDashboardLayout from 'src/layouts/doctor-dashboard/layout';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import DoctorProfileViewPage from 'src/pages/doctor-dashboard/profile/doctor-profile-view-page';
import DoctorProfileEditPage from 'src/pages/doctor-dashboard/profile/doctor-profile-edit-page';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/doctor-dashboard/doctor-dashboard-home'));
const PageClinicList = lazy(() => import('src/pages/dashboard/clinic-list'));

// ----------------------------------------------------------------------

export const doctorDashboardRoutes = [
  {
    path: 'doctor-dashboard',
    element: (
      <DoctorAuthGuard>
        <DoctorDashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DoctorDashboardLayout>
      </DoctorAuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'profile', element: <DoctorProfileViewPage /> },
      { path: 'profile/edit', element: <DoctorProfileEditPage /> },
    ],
  },
];
