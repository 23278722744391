import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField, Chip, Box, Stack } from '@mui/material';
import { useState } from 'react';

// ----------------------------------------------------------------------

export default function RHFTextFieldWithChips({
  name,
  helperText,
  type,
  setValue,
  initialValue = [], // default to empty array if not provided
  ...other
}) {
  const { control } = useFormContext();
  const [chips, setChips] = useState(initialValue);
  console.log(name,chips);
  const [inputValue, setInputValue] = useState(''); // Manage input value separately

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const value = event.target.value.trim();
      if (value && !chips.includes(value)) {
        const newValue = [...chips, value];
        setChips(newValue);
        setValue(name, newValue);
        setInputValue(''); // Clear input after adding chip
      }
    }
  };

  const handleDeleteChip = (chipToDelete) => () => {
    const newChipsValue = chips.filter((chip) => chip !== chipToDelete);
    setChips(newChipsValue);
    setValue(name, newChipsValue);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <TextField
            {...field}
            fullWidth
            type={type}
            sx={{
              color: '#D92D7F',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#D92D7F', // Default border color
                },
                '&:hover fieldset': {
                  borderColor: '#D92D7F', // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#D92D7F', // Border color when focused
                },
                '&.Mui-disabled fieldset': {
                  borderColor: '#D92D7F', // Border color when disabled
                },
              },
            }}
            value={inputValue} // Bind TextField to inputValue, not chips
            onChange={handleInputChange} // Handle input change
            onKeyDown={handleKeyDown} // Add chip on "Enter" key press
            {...other}
          />
          <Box sx={{ mt: 1 }}>
            <Stack direction="row" spacing={1}>
              {chips.map((chip) => (
                <Chip
                  key={chip}
                  label={chip}
                  onDelete={handleDeleteChip(chip)}
                  sx={{ backgroundColor: '#D92D7F', color: 'white' }}
                />
              ))}
            </Stack>
          </Box>
          {helperText && <p>{helperText}</p>}
        </>
      )}
    />
  );
}

RHFTextFieldWithChips.propTypes = {
  helperText: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  initialValue: PropTypes.array, // Add prop type for initialValue
};