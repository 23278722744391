import React from 'react';
import { Helmet } from 'react-helmet-async';
import BusinessLandingPageView from 'src/sections/BusinessLandingPage/business-landing-page-view';

export default function BusinessLandingPage() {
  return (
    <>
      <Helmet>
        <title> Clinic Dashboard Home Page</title>
      </Helmet>

      <BusinessLandingPageView />
    </>
  );
}
