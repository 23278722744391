import { Box, Chip, Container, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { usePathname } from 'src/routes/hooks';
import { LoadingScreen } from 'src/components/loading-screen';
import { useSettingsContext } from 'src/components/settings';
import { getEnquiryComments, getEnquiryDetails } from 'src/services/enquiry.service';
import EditEnquiryPopup from './edit-enquiry-popup';

const EnquiryDetailsPage = () => {
  const settings = useSettingsContext();
  const [enquiryDetails, setEnquiryDetails] = useState(null);
  const [enquiryComments, setEnquiryComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enquiryId } = useParams();

  const STATUS_OPTIONS = {
    open: { label: 'Open', color: 'default' },
    assigned_to_clinic: { label: 'Assigned To Clinic', color: 'success' },
    cancelled: { label: 'Cancelled', color: 'error' },
    ready_to_buy: { label: 'Ready To Buy', color: 'warning' },
    free_consultation: { label: 'Free Consultation', color: 'primary' },
    package_sold: { label: 'Package Sold', color: 'success' },
  };

  const path = usePathname();
  const isDashboard = path.startsWith('/dashboard');

  const statusOptions = isDashboard
    ? [
        { value: 'open', label: 'Open' },
        { value: 'assigned_to_clinic', label: 'Assigned To Clinic' },
        { value: 'cancelled', label: 'Cancelled' },
      ]
    : [
        { value: 'ready_to_buy', label: 'Ready To Buy' },
        { value: 'free_consultation', label: 'Free Consultation' },
        { value: 'package_sold', label: 'Package Sold' },
        { value: 'cancelled', label: 'Cancelled' },
      ];

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);

    Promise.all([getEnquiryDetails(enquiryId), getEnquiryComments(enquiryId)])
      .then(([details, comments]) => {
        if (isMounted) {
          setEnquiryDetails(details);
          setEnquiryComments(comments);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error fetching enquiry data:', error);
        if (isMounted) setIsLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, [enquiryId]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Stack direction="row" justifyContent="space-between" sx={{ marginBottom: '14px' }}>
        <Typography variant="h4">Enquiry Details</Typography>
      </Stack>
      {isLoading ? (
        <Box
          sx={{
            minHeight: '80vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingScreen />
        </Box>
      ) : (
        enquiryDetails && (
          <Stack direction="row" gap="14px" flexWrap="wrap">
            <Grid container spacing={{ xs: '14px', lg: '44px' }}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6">Customer Name</Typography>
                <Typography variant="body1">{enquiryDetails.name}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6">Phone Number</Typography>
                <Typography variant="body1">{enquiryDetails.phone_number}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6">Area of Concern</Typography>
                <Typography variant="body1">{enquiryDetails.area_of_concern}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6">Status</Typography>
                  <EditEnquiryPopup
                    fieldName="status"
                    fieldContent={enquiryDetails.status}
                    options={statusOptions}
                    enquiryId={enquiryId}
                  />
                </Stack>
                <Chip
                  label={STATUS_OPTIONS[enquiryDetails.status]?.label}
                  color={STATUS_OPTIONS[enquiryDetails.status]?.color}
                />
              </Grid>
              {isDashboard && (
                <Grid item xs={12} sm={6}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h6">Assign to Clinic</Typography>
                    <EditEnquiryPopup fieldName="assigned_to" enquiryId={enquiryId} />
                  </Stack>
                  <Typography variant="body1">
                    {enquiryDetails.assigned_to?.legal_name || 'Not Assigned'}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="h6">Message</Typography>
                <Typography variant="body1">{enquiryDetails.message}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h6">Comments</Typography>
                  <EditEnquiryPopup fieldName="comments" enquiryId={enquiryId} />
                </Stack>
                {enquiryComments.map((comment, index) => (
                  <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body2">{comment.comment}</Typography>
                    <Typography
                      variant="body1"
                      style={{ alignSelf: 'flex-end', fontWeight: 'bold' }}
                    >
                      Posted by:{' '}
                      {`${comment.user_id.first_name} ${comment.user_id.last_name} on ${new Date(
                        comment.created_at
                      ).toLocaleDateString()}`}
                    </Typography>
                  </div>
                ))}
              </Grid>
            </Grid>
          </Stack>
        )
      )}
    </Container>
  );
};

export default EnquiryDetailsPage;
