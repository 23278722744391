import { Helmet } from 'react-helmet-async';
// sections
import ClinicAddPackageView from 'src/sections/clinic-dashboard/packages/clinic-package-add-view';

// ----------------------------------------------------------------------

export default function ClinicAddPackagePage() {
  return (
    <>
      <Helmet>
        <title>Add Package</title>
      </Helmet>

      <ClinicAddPackageView />
    </>
  );
}
