import React from 'react';
import { Helmet } from 'react-helmet-async';
import DoctorProfileView from 'src/sections/doctor-dashboard/profile/doctor-profile-view';

const DoctorProfileViewPage = () => (
  <>
    <Helmet>
      <title>Doctor Profile</title>
    </Helmet>

    <DoctorProfileView />
  </>
);

export default DoctorProfileViewPage;
