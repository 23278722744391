import React from 'react';

const YouTubeEmbed = ({ videoUrl }) => {
  // Extract the video ID from the URL
  const videoId = videoUrl.split('v=')[1].split('&')[0];
  console.log(videoId);

  return (
    <div>
      <iframe
        width="100%"
        height="500px"
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};

export default YouTubeEmbed;
