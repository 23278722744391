import { deleteData, getData, postData, putData } from 'src/utils/axios';
import { urlMap } from 'src/utils/urls';

export const getEnquires = async (pageValue = 1, limitValue = 10, status = '') => {
  // Construct the URL with page, limit, and status parameters
  let url = `${urlMap.enquiry.list
    .replace('pageValue', `${pageValue}`)
    .replace('limitValue', `${limitValue}`)}`;

  // If status is provided, append it as a query parameter
  if (status) {
    url += `&status=${status}`;
  }

  // Fetch data with the constructed URL
  return getData(url);
};

export const getClinicEnquires = async (pageValue = 1, limitValue = 10, status = '', clinicId) => {
  let url = `${urlMap.clinic.enquires
    .replace('pageValue', `${pageValue}`)
    .replace('limitValue', `${limitValue}`)
    .replace(':clinicId', clinicId)}`;

  if (status) {
    url += `&status=${status}`;
  }

  return getData(url);
};

export const getEnquiryDetails = async (enquiryId) => {
  const url = `${urlMap.enquiry.details.replace(':enquiryId', enquiryId)}`;
  return getData(url);
};

export const updateEnquiry = async (enquiryId, updatedDetails) => {
  const url = `${urlMap.enquiry.update.replace(':enquiryId', enquiryId)}`;
  return putData(url, updatedDetails);
};

export const addEnquiryComment = async (details) => {
  const url = `${urlMap.enquiry.comment.add}`;
  return postData(url, details);
};

export const getEnquiryComments = async (enquiryId) => {
  const url = `${urlMap.enquiry.comment.list.replace('{enquiryId}', enquiryId)}`;
  return getData(url);
};
