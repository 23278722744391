import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import { deletePackage } from 'src/services/package.service';
import { useRouter } from 'src/routes/hooks';
import Iconify from '../iconify';

export default function DeleteDialog({ packageId }) {
  const [open, setOpen] = React.useState(false);
  const router = useRouter();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClickDelete = async () => {
    try {
      await deletePackage(packageId);
      // Add any additional logic here, like showing a success message or refreshing the page.
    } catch (error) {
      // Handle the error appropriately.
      console.error('Failed to delete package:', error);
    }
    handleClose(); // Close the dialog after deletion.
    router.reload();
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <Iconify icon="tabler:trash" />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Package</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this package?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={onClickDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
