import { Helmet } from 'react-helmet-async';
import ClinicBusinessProfileEdit from 'src/sections/clinic-dashboard/business-profile/clinic-business-profile-edit';
import ClinicPackagesView from 'src/sections/clinic-dashboard/packages/clinic-packages-view';
// sections

// ----------------------------------------------------------------------

export default function ClinicPackagesPage() {
  return (
    <>
      <Helmet>
        <title>Clinic packages</title>
      </Helmet>

      <ClinicPackagesView />
    </>
  );
}
