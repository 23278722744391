import { deleteData, getData, postData, putData } from 'src/utils/axios';
import { urlMap } from 'src/utils/urls';

export const getProcedures = async (pageValue = 1, limitValue = 10) => {
  const url = `${urlMap.procedures.list
    .replace('pageValue', `${pageValue}`)
    .replace('limitValue', `${limitValue}`)}`;
  return getData(url);
};

export const addProcedure = async (data) => {
  const url = `${urlMap.procedures.add}`;
  return postData(url, data);
};

export const removeProcedure = async (procedureId) => {
  const url = `${urlMap.procedures.remove.replace(':procedureId', procedureId)}`;
  return deleteData(url);
};

export const getProcedureDetails = (procedureId) => {
  const url = `${urlMap.procedures.remove.replace(':procedureId', procedureId)}`;
  return getData(url);
};

export const addProcedureContent = async (procedureId, contentData) => {
  const url = `${urlMap.procedures.addContent.replace(':procedureId', procedureId)}`;
  return putData(url, contentData);
};

export const editProcedure = async (procedureId, updatedData) => {
  const url = `${urlMap.procedures.edit.replace(':procedureId', procedureId)}`;
  return putData(url, updatedData);
};
