import React from 'react';
import { Helmet } from 'react-helmet-async';
import AddClinicDoctorView from 'src/sections/clinic-dashboard/doctors/add-clinic-doctor-view';

const AddClinicDoctorPage = () => (
  <>
    <Helmet>
      <title>Add Clinic Doctor</title>
    </Helmet>

    <AddClinicDoctorView />
  </>
);

export default AddClinicDoctorPage;
