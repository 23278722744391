import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';
import { useResponsive } from 'src/hooks/use-responsive';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false,imageUrl="/assets/icons/navbar/cosma_logo.svg", sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const lgUp = useResponsive('up', 'lg');

  // OR using local (public folder)
  // -------------------------------------------------------
  const logo = (
    <Box
      component="img"
      src={imageUrl}
      sx={{  cursor: 'pointer', ...sx, maxWidth:168, }}
    />
  );

  // const logo = (
  //   <Box
  //     ref={ref}
  //     component="div"
  //     sx={{
  //       width: 40,
  //       height: 40,
  //       display: 'inline-flex',
  //       ...sx,
  //     }}
  //     {...other}
  //   >
  //     <svg height="32" width="32" xmlns="http://www.w3.org/2000/svg">
  //       <path d="m0 0h32v32c-10.56 0-21.12 0-32 0 0-10.56 0-21.12 0-32z" fill="#fdf9fb" />
  //       <path
  //         d="m0 0c3.89423016 2.13800871 6.65114833 4.23233331 9 8 .86120395 4.77153538.70334178 8.79427124-1.75 13-4.13768593 3.67794305-7.54756068 4.49843939-12.9921875 4.390625-3.88722812-.67253082-5.59940123-2.53283289-8.2578125-5.390625-1.35812586-4.07437758-1.65365174-7.74400091-1-12 1.67238584-3.17084355 4.01339727-5.29983032 6.9375-7.375 2.91022112-.88188519 5.02866714-.98905994 8.0625-.625z"
  //         fill="#d93483"
  //         transform="translate(18 5)"
  //       />
  //       <g transform="translate(17.8125 11.625)">
  //         <path
  //           d="m0 0c.721875.12375 1.44375.2475 2.1875.375.48539326 7.28089888.48539326 7.28089888-1.6875 10.5-2.69226221 1.74633224-4.16410094 1.84982212-7.3125 1.5-.8125-2.625-.8125-2.625-1-6 3.66576819-5.82210243 3.66576819-5.82210243 7.8125-6.375z"
  //           fill="#dc4c90"
  //         />
  //         <path
  //           d="m0 0c.721875.12375 1.44375.2475 2.1875.375-.33 1.65-.66 3.3-1 5-.33-.99-.66-1.98-1-3-3.16717283 1.58358642-4.73268318 2.45263699-6.3125 5.6875-.226875.763125-.45375 1.52625-.6875 2.3125-.8125-1.625-.8125-1.625-1-4 3.82022472-5.84269663 3.82022472-5.84269663 7.8125-6.375z"
  //           fill="#f5ddea"
  //         />
  //       </g>
  //     </svg>
  //   </Box>
  // );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
