import PropTypes from 'prop-types';
import { Avatar, Button, ListItemText, TableCell, TableRow } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import ClinicTableRow from 'src/sections/clinics/clinic-table-row';

export default function ClinicDoctorTableRow({
  row,
  setTableData,
  onViewRow,
}) {
  const { staff_id,name,telephone_number,email,roles,status } = row;

  // refs

  // states
  
  
  return (
    <>
      <TableRow
        onClick={onViewRow}
        hover
        sx={{ cursor: 'pointer' }}
      >
        
        <TableCell
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            maxWidth: 250,
          }}
        >
          <Avatar alt={name} sx={{ mr: 2 }}>
            {name.charAt(0).toUpperCase()}
          </Avatar>

          <ListItemText
            sx={{
              maxWidth: 250,
            }}
            primary={name}
            primaryTypographyProps={{
              typography: 'body2',
              maxWidth: 250,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          />
        </TableCell>

        <TableCell>
          <Label variant="ghost" color="info">
            {email}
          </Label>
        </TableCell>
        <TableCell>
          <Label variant="ghost" color="info">
            {telephone_number}
          </Label>
        </TableCell>

        <TableCell>
          <Label variant="ghost" color="info">
            {roles.map((role) => role).join(', ')}
          </Label>
        </TableCell>
       <TableCell>
        <Label variant="ghost" color="info">
          {status}
        </Label>
      </TableCell>
       {/* <TableCell>
           <IconButton size="small" onClick={() => onViewRow(staff_id)}>
                    <Iconify icon="tabler:eye" width={24} />
          </IconButton>
          <UpdateUserPassword email={email} />
       </TableCell> */}

        
      </TableRow>

      
    </>
  );
}

ClinicTableRow.propTypes = {
  row: PropTypes.object,
  setTableData: PropTypes.func,
  onViewRow: PropTypes.func,
};
