import * as React from 'react';
import { Paper } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useLocation } from 'react-router';
import {  useRouter } from 'src/routes/hooks';
import { useBottomNavData } from './config-navigation';

export default function NavBottom() {
  const router = useRouter();
  const data = useBottomNavData();
  const location = useLocation();

  // Determine the active route based on the current path
  const activeRoute = data.find((route) => route.path === location.pathname);

  const [value, setValue] = React.useState(activeRoute?.path || data[0].path);

  React.useEffect(() => {
    setValue(activeRoute?.path);
  }, [router.pathname, activeRoute]);

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        borderRadius: '10px',
        borderTop: '1px solid #D92D7F',
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        sx={{
          paddingX: '10px',
          color: '#D92D7F',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
        }}
        value={value}
        onChange={(event, newValue) => {
          const selectedRoute = data.find((route) => route.path === newValue);
          setValue(selectedRoute.path);
          router.push(selectedRoute.path);
        }}
      >
        {data.map((routeDetails) => (
          <BottomNavigationAction
            key={routeDetails.path}
            sx={{ color: '#D92D7F' }}
            label={routeDetails.title}
            icon={routeDetails.icon}
            value={routeDetails.path}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
}