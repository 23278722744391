import { Helmet } from 'react-helmet-async';
import ClinicBusinessProfileEdit from 'src/sections/clinic-dashboard/business-profile/clinic-business-profile-edit';
// sections

// ----------------------------------------------------------------------

export default function ClinicBusinessProfileEditPage() {
  return (
    <>
      <Helmet>
        <title>Edit Clinic Business Profile</title>
      </Helmet>

      <ClinicBusinessProfileEdit />
    </>
  );
}
