import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, Box, useTheme } from '@mui/material';

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const RHFSelectInput = ({
  name,
  control,
  label,
  options = [],
  placeholder = 'Select an option',
}) => {
  const theme = useTheme();

  return (
    <FormControl fullWidth>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            label={label}
            id={name}
            margin="dense"
            fullWidth
            input={<OutlinedInput label={label} />}
            renderValue={(selected) => selected || placeholder}
          >
            {options.map((option) => (
              <MenuItem
                key={option} // Ensure each option is unique
                value={option} // Use option value directly
                style={getStyles(option, field.value, theme)} // Apply styles
              >
                {option} {/* Display option text */}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default RHFSelectInput;
