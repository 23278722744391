import { yupResolver } from '@hookform/resolvers/yup';
import {
  alpha,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  Alert,
  Snackbar,
  useTheme,
  Select,
  FormControl,
  MenuItem,
  Chip,
  OutlinedInput,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { useSettingsContext } from 'src/components/settings';
import RHFMarkdownEditor from 'src/components/markdown-editor/markdownEditor';
import RHFImageUpload from 'src/components/hook-form/rhf-image-upload';
import RHFSelectInput from 'src/components/hook-form/rhf-select-field';
import { useAuthContext } from 'src/auth/hooks';
import Iconify from 'src/components/iconify';
import { addPackage } from 'src/services/package.service';
import { getClinicDoctors } from 'src/services/clinic.service';
import {
  getBodyParts,
  getConcerns,
  getProcedures,
  getTreatmentId,
} from 'src/services/treatments.service';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function ClinicAddPackageView() {
  const settings = useSettingsContext();
  const { user } = useAuthContext();
  const theme = useTheme();
  const navigate = useNavigate();
  const [avalibleDoctors, setAvalibleDoctors] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [bodyParts, setBodyParts] = useState([]);
  const [procedures, setProcedures] = useState([]); // Store procedures
  const [concerns, setConcerns] = useState([]); // Store concerns
  const [loading, setLoading] = useState(true); // Loading state
  const [selectedBodyPart, setSelectedBodyPart] = useState('');
  const [selectedProcedure, setSelectedProcedure] = useState('');
  const [selectedConcern, setSelectedConcern] = useState('');
  const [treatmentIds, setSelectedTreatmentIds] = useState([]);

  useEffect(() => {
    setLoading(true);
    getClinicDoctors(user.clinic_id).then((doctorsList) => {
      setAvalibleDoctors(doctorsList);
    });

    getBodyParts().then((avalbileBodyParts) => {
      setBodyParts(avalbileBodyParts);
      setLoading(false); // Data fetching is done
    });
  }, [user.clinic_id]);

  const packageSchema = Yup.object().shape({
    package_name: Yup.string().required('Package name is required'),
    price: Yup.number()
      .required('Price is required')
      .positive('Price must be positive')
      .min(0, 'Price cannot be negative'),
    package_promo_image: Yup.array().of(Yup.string().nullable()),
    package_description: Yup.string(),
    pre_post_images: Yup.array().of(Yup.string()).nullable(),
    package_inclusions: Yup.array().of(Yup.string()).nullable(),
    package_exclusions: Yup.array().of(Yup.string()).nullable(),
    package_doctors: Yup.array().of(Yup.string()).nullable(),
  });

  const packageDefaultValues = {
    package_name: '',
    price: 0,
    package_doctors: [],
    package_promo_image: [],
    package_description: '',
    pre_post_images: [],
    package_inclusions: [],
    package_exclusions: [],
  };

  const {
    control,
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(packageSchema),
    defaultValues: packageDefaultValues,
  });

  console.log(errors);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const packagePromoImage = watch('package_promo_image');
  const prePostImages = watch('pre_post_images');
  const fileName = `package_promo_${new Date().toISOString()}`;
  const folderName = `images/packages/${user.clinic_id}`;

  const prePostImagesFileName = `package_pre_post_images_${new Date().toISOString()}`;
  const prePostImagesFolderName = `images/packages/pre_post_images/${user.clinic_id}`;

  const onSubmit = (data) => {
    const finalData = {
      clinic_id: user.clinic_id,
      ...data,
      treatment_id: treatmentIds,
    };
    console.log(finalData);
    addPackage(finalData)
      .then((response) => {
        reset();
        setSnackbarMessage('Package created successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch((error) => {
        setSnackbarMessage(`Error: ${error.message}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleBodyPartChange = async (event) => {
    const bodyPartId = event.target.value;
    console.log(bodyPartId);
    setSelectedBodyPart(bodyPartId);
    setLoading(true);

    // Fetch procedures based on the selected body part
    const proceduresList = await getProcedures(bodyPartId);
    setProcedures(proceduresList);
    setLoading(false);
  };

  const handleProcedureChange = async (event) => {
    const procedureId = event.target.value;
    setSelectedProcedure(procedureId);
    setLoading(true);

    // Fetch concerns based on the selected procedure
    const concernsList = await getConcerns(selectedBodyPart, procedureId);
    setConcerns(concernsList);
    setLoading(false);
  };

  const handleConcernChange = async (event) => {
    const concern = event.target.value;
    setSelectedConcern(concern);

    const treatment_id = await getTreatmentId(selectedBodyPart, selectedProcedure, concern);
    setSelectedTreatmentIds([treatment_id]);
  };

  return (
    <Container
      sx={{
        background: { lg: 'white' },
        margin: 4,
        marginX: 'auto',
        padding: 3,
        maxWidth: 840,
        borderRadius: 4,
      }}
    >
      <Stack direction="row" gap="14px" alignItems="center" sx={{ marginBottom: '14px' }}>
        <IconButton
          aria-label="back-button"
          size="large"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Iconify icon="tabler:chevron-left" />
        </IconButton>
        <Typography variant="h4"> Create Your Package here </Typography>
      </Stack>

      <FormProvider methods={{ control, handleSubmit }} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={{ xs: '14px', lg: '44px' }}>
          <Grid item xs={12} lg={6}>
            <RHFTextField
              name="package_name"
              label="Package Name"
              margin="dense"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Controller
              name="package_doctors"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="roles">Doctors</InputLabel>
                  <Select
                    {...field}
                    label="Doctors"
                    id="doctors"
                    margin="dense"
                    multiple
                    fullWidth
                    placeholder="select a Doctor"
                    input={<OutlinedInput label="Doctor" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((id) => {
                          const doctor = avalibleDoctors.find((doc) => doc.user_id.id === id);
                          return (
                            <Chip
                              key={id}
                              label={`${doctor?.user_id.first_name} ${doctor?.user_id.last_name}`}
                            />
                          );
                        })}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {avalibleDoctors.map((doctorDetails) => (
                      <MenuItem
                        key={doctorDetails.user_id.id}
                        value={doctorDetails.user_id.id}
                        style={getStyles(
                          `${doctorDetails.user_id.first_name} ${doctorDetails.user_id.last_name}`,
                          field.value,
                          theme
                        )}
                      >
                        {`${doctorDetails.user_id.first_name} ${doctorDetails.user_id.last_name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ marginBottom: '14px' }} variant="h5">
              Package Details
            </Typography>
            <Grid item xs={12}>
              <RHFMarkdownEditor
                name="package_description"
                label="Package Description"
                fileName={`package_image_${new Date().toISOString()}`}
                folderName="images/packages"
                required
                margin="dense"
                rows={4}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <RHFTextField
              name="price"
              label="Package Price"
              type="number"
              required
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth margin="dense">
              <InputLabel id="body-part-select">Select Body Part</InputLabel>
              <Select
                id="body-part-select"
                value={selectedBodyPart}
                onChange={handleBodyPartChange}
                label="Select Body Part"
                required
              >
                {bodyParts.map((part) => (
                  <MenuItem key={part} value={part}>
                    {part}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            {loading ? (
              <CircularProgress />
            ) : (
              <FormControl fullWidth margin="dense">
                <InputLabel id="procedure-select">Select Procedure</InputLabel>
                <Select
                  id="procedure-select"
                  value={selectedProcedure}
                  onChange={handleProcedureChange}
                  label="Select Procedure"
                  required
                >
                  {procedures.map((procedure) => (
                    <MenuItem key={procedure} value={procedure}>
                      {procedure}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth margin="dense">
              <InputLabel id="concern-select">Select Concern</InputLabel>
              <Select
                id="concern-select"
                disabled={loading}
                onChange={handleConcernChange}
                defaultValue=""
                label="Select Concern"
                required
              >
                {concerns.map((concern) => (
                  <MenuItem key={concern} value={concern}>
                    {concern}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <RHFImageUpload
              initialImage={packagePromoImage}
              fileName={fileName}
              setValue={setValue}
              name="package_promo_image"
              folderName={folderName}
              displayImages
              multipleFiles
              maxFiles={1}
              label="Package Promo Image"
              variant="outlined"
              width={1530}
              height={750}
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <RHFImageUpload
              initialImage={prePostImages}
              fileName={prePostImagesFileName}
              setValue={setValue}
              name="pre_post_images"
              folderName={prePostImagesFolderName}
              filesRequired={1}
              displayImages
              multipleFiles
              maxFiles={10}
              label="Pre/Post Images"
              variant="outlined"
              width={172}
              height={240}
              required
            />
          </Grid>
          <Grid container item alignItems="end" xs={12} justifyContent="flex-end">
            <Button
              variant="contained"
              sx={{
                width: 1,
                boxShadow: 10,
                fontSize: { lg: '16px', xs: '12px' },
                background: '#D92D7F',
                ':hover': { background: '#D92D7F' },
              }}
              type="submit"
            >
              Save Package
            </Button>
          </Grid>
        </Grid>
      </FormProvider>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
