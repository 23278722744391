import PropTypes from 'prop-types';
import { Avatar, Button, ListItemText, TableCell, TableRow } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';

export default function ClinicTableRow({
  row,
}) {
  const { clinic_id,brand,legal_name } = row;
  const router = useRouter();
  const handleViewRow = () => {
    console.log('on view row click', clinic_id);
    router.push(paths.dashboard.clinic.details.replace(':id', clinic_id));
  }

  // refs

  // states
  
  
  return (
    <>
      <TableRow
        onClick={handleViewRow}
        hover
        sx={{ cursor: 'pointer' }}
      >
        
        <TableCell
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            maxWidth: 250,
          }}
        >
          <Avatar alt={brand} sx={{ mr: 2 }}>
            {brand.charAt(0).toUpperCase()}
          </Avatar>

          <ListItemText
            sx={{
              maxWidth: 250,
            }}
            primary={brand}
            primaryTypographyProps={{
              typography: 'body2',
              maxWidth: 250,
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          />
        </TableCell>

        <TableCell>
          <Label variant="ghost" color="info">
            {legal_name}
          </Label>
        </TableCell>
        <TableCell>
          <Label variant="ghost" color="info">
            {row.telephone_number}
          </Label>
        </TableCell>

        <TableCell>
          <Label variant="ghost" color="info">
            {`${row.address.city},${row.address.state}`}
          </Label>
        </TableCell>
       <TableCell>
         <IconButton size="small" onClick={() => handleViewRow()}>
                    <Iconify icon="tabler:eye" width={24} />
          </IconButton>
       </TableCell>

        
      </TableRow>

      
    </>
  );
}

ClinicTableRow.propTypes = {
  row: PropTypes.object,
};
